import { Form, type LoaderData, LosseLink, losseLoader, useFetcher, useLoaderData, useSearchParams } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import type {
  ContentNode,
  Page,
  Page_Flexcontent_Flex_Banner,
  Post,
  ProductItem as ProductItemProps,
  Project as ProjectProps,
  Vacatures,
  WpPageInfo
} from '~/graphql/types'
import { ProductItem } from '../Posts/post-types/ProductItem'
import Project from '../Posts/post-types/Project'
import Vacancy from '../Posts/post-types/Vacancy'
import Button from '~/components/elements/Button'
import { Title } from '~/components/elements/Title'

const contentTypeLiteral: {
  [key: string]: string
} = {
  vacatures: 'Vacature',
  page: 'Pagina',
  post: 'Bericht',
  project: 'Project',
  product: 'Product'
}

export default function BannerSearch({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const data = useLoaderData<LoaderData<Page>>()
  const [searchParams, setSearchParams] = useSearchParams()
  const fetcher = useFetcher<{ pageInfo: WpPageInfo; edges: { node: ContentNode }[] }>()

  useEffect(() => {
    if (!searchParams.has('q')) return

    const query = losseLoader({
      name: 'Search',
      variables: {
        search: searchParams.get('q') || '',
        after: searchParams.get('after') || ''
      }
    }).replace('/__query', '/query-data')

    fetcher.load(query)
    // eslint-disable-next-line
  }, [searchParams.get('q'), searchParams.get('after')])

  return (
    <section data-component="BannerSearch" className="bg-site-grey min-h-screen-dynamic">
      <div className="bg-site-alt py-10 section--with-bg">
        <div className="container">
          <Breadcrumbs />
          <h1 className="font-semibold text-6xl mt-10 text-white md:pl-8 lg:pl-16">{fields.title}</h1>

          <Form action={new URL(data.request.url).pathname} method="get" className="relative drop-shadow-site-shadow flex mt-16">
            <input
              type="search"
              className="h-12 rounded-[7px] w-full px-4 text-xl bg-site-grey"
              name="q"
              placeholder="Typ hier jouw zoekopdracht"
              defaultValue={searchParams.get('q') || undefined}
            />
            <button className="btn--accent border-none absolute right-0 top-0 h-full rounded-[7px] py-1" type="submit">
              {fields.title}
            </button>
          </Form>
        </div>
      </div>

      <div className="bg-site-grey">
        <div className="py-10 container">
          {(fetcher.state === 'loading' || fetcher.state === 'submitting') && (
            <div className="flex justify-center py-10">
              <Loading />
            </div>
          )}
          {fetcher.state === 'idle' && (
            <>
              <div className="flex flex-col sm:gap-y-6 gap-y-4">
                {(!fetcher.data || fetcher.data?.edges?.length === 0) && (
                  <div className="max-w-screen-lg mx-auto">
                    <Title size={fields.title.length > 50 ? 'small' : 'medium'} className="md:text-center">
                      HEB JE ADVIES NODIG?
                    </Title>
                    <Content className="max-md:children-p:!text-left mt-4 lg:mt-10 md:px-20">
                      <p className="text-center">
                        Onze projectinrichters zijn er om vragen te beantwoorden, oplossingen te bieden en de nieuwste trends met u te
                        delen. Advies te geven voor het creëren van een kantoorruimte die zowel functioneel als stijlvol is.
                      </p>
                    </Content>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-6 sm:mt-10 lg:mt-20 gap-4 sm:gap-6 lg:gap-9">
                      <div className="bg-white rounded-[7px] flex flex-col justify-between gap-4 lg:gap-6 shadow-site-shadow p-4 sm:p-6 lg:p-11">
                        <div className="flex flex-col lg:gap-6 gap-4">
                          <h3 className="font-semibold text-2xl lg:text-3xl">Showroom afspraak</h3>
                          <Content>
                            <p>
                              Ontdek ons ruime assortiment kantoormeubelen en ervaar de productkwaliteit en functionaliteit tijdens een
                              persoonlijk consult. Tot snel!
                            </p>
                          </Content>
                        </div>

                        <div className="mt-2">
                          <Button buttonstyle={'accent'} to="/showroom-afspraak" target="" className="btn--accent">
                            Direct afspreken
                          </Button>
                        </div>
                      </div>
                      <div className="bg-white rounded-[7px] flex flex-col justify-between gap-4 lg:gap-6 shadow-site-shadow p-4 sm:p-6 lg:p-11">
                        <div className="flex flex-col lg:gap-6 gap-4">
                          <h3 className="font-semibold text-2xl lg:text-3xl">Neem contact met ons op</h3>
                          <Content>
                            <p>
                              Maak gebruik van onze waardevolle expertise op het gebied van het inrichten van kantoren, scholen, zalen of
                              horeca.
                            </p>
                          </Content>
                        </div>

                        <div className="mt-2">
                          <Button buttonstyle={'base_outlined'} to="/contact" target="" className="btn--base-outlined">
                            Schrijf ons een bericht
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
                  {fetcher?.data?.edges.map((edge) => {
                    if (!edge) return null
                    if (!edge.node) return null
                    if (edge.node.contentTypeName === 'fragment') return null

                    const node = edge.node as Post | Page

                    // @ts-ignore
                    if (edge.node.contentTypeName === 'productitem' && edge?.node?.parentDatabaseId !== null) {
                      const product = edge.node as ProductItemProps

                      return <ProductItem key={product.databaseId} fields={product} />
                    }

                    if (edge.node.contentTypeName === 'project') {
                      const project = edge.node as ProjectProps

                      return (
                        <div key={project.databaseId} className="col-span-1 md:col-span-2">
                          <Project data={project} />
                        </div>
                      )
                    }

                    if (edge.node.contentTypeName === 'vacancy') {
                      const vacancy = edge.node as Vacatures

                      return (
                        <div key={vacancy.databaseId} className="col-span-1 md:col-span-2">
                          <Vacancy data={vacancy} />
                        </div>
                      )
                    }

                    return (
                      <LosseLink
                        to={edge.node.uri || '#'}
                        className="flex bg-white rounded-[7px] shadow-site-shadow p-8 flex-col justify-between"
                        key={node.id}
                      >
                        <div>
                          <div className="text-site-alt font-light mr-auto mb-1">
                            {contentTypeLiteral[node.contentTypeName]
                              ? contentTypeLiteral[node.contentTypeName]
                              : contentTypeLiteral['page']}
                          </div>
                          <div className="text-xl sm:text-2xl line-clamp-1 font-bold pr-10">{node.title}</div>
                        </div>

                        <div className="flex justify-center mt-16">
                          <Button className="w-full" icons="arrow" buttonstyle="base_outlined" to={node.uri}>
                            Bekijk
                          </Button>
                        </div>
                      </LosseLink>
                    )
                  })}
                </div>
              </div>
              <div className="mt-10 flex justify-between">
                {fetcher.data?.pageInfo?.hasPreviousPage && (
                  <button
                    onClick={() => {
                      searchParams.delete('after')
                      searchParams.set('before', fetcher.data.pageInfo?.endCursor || '')
                      setSearchParams(searchParams)
                      // window.scrollTo(0, 0)
                    }}
                    className="flex items-center text-sm uppercase"
                    type="button"
                  >
                    <img src="/icons/chevron-left-black.svg" alt="Chevron" className="w-4 h-4" />
                    <span className="ml-2">Vorige</span>
                  </button>
                )}
                {fetcher.data?.pageInfo?.hasNextPage && (
                  <button
                    onClick={() => {
                      searchParams.delete('before')
                      searchParams.set('after', fetcher.data.pageInfo?.endCursor || '')
                      setSearchParams(searchParams)
                      // window.scrollTo(0, 0)
                    }}
                    className="flex items-center text-sm uppercase ml-auto"
                    type="button"
                  >
                    <span className="mr-2 text-ab-tidal">Volgende</span>
                    <img src="/icons/chevron-down.svg" alt="Chevron" className="w-4 h-4 -rotate-90" />
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
