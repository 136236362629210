import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { Merk, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Brand from './post-types/Brand';

export default function BrandsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="BrandsOverview">
      <div className="bg-site-alt">
        <div className="container">
          <div className="flex flex-col gap-7 lg:gap-11 pb-10 sm:pb-14 lg:pb-24 pt-6 sm:pt-11">
            <Breadcrumbs />
            <h1 className="text-4xl sm:text-5xl xl:text-6xl pl-6 lg:pl-16 text-white font-semibold">{fields.title}</h1>
          </div>
        </div>
      </div>
      <div className="bg-site-grey section section--with-bg">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 xm:gap-4 md:gap-6">
            {fields?.posts?.edges?.filter((brand) => brand?.node?.parentDatabaseId === null).map(({ node }) => {
              const brand = node as Merk

              return <Brand darktext={fields.textcolor === 'black'} key={brand.databaseId} data={brand} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
