import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Arrow } from '~/components/elements/Arrow'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import FsLightbox from 'fslightbox-react'
import clsx from 'clsx'

export default function ContentImageSlider({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  return (
    <section
      data-component="ContentImageSlider"
      className={clsx(
        fields.background === '#E9EAEA' && 'section section--with-bg bg-site-grey',
        fields.background === '#393C42' && 'section section--with-bg bg-site-base',
        fields.background === '#FFFFFF' && 'section'
      )}
    >
      <div className="container">
        <div className="flex items-center gap-6 mb-2 sm:mb-4">
          <div id={`contentimage_slider_prev_${index}`} className="cursor-pointer !block">
            <Arrow color={fields.background === '#393C42' ? 'white' : 'base'} orientation="left" hover="accent" size="medium" />
          </div>
          <div id={`contentimage_slider_next_${index}`} className="cursor-pointer !block">
            <Arrow color={fields.background === '#393C42' ? 'white' : 'base'} hover="accent" size="medium" />
          </div>
        </div>
        <Slider
          modules={[Navigation]}
          navigation={{
            prevEl: `#contentimage_slider_prev_${index}`,
            nextEl: `#contentimage_slider_next_${index}`
          }}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1.3,
              spaceBetween: 16
            },
            1024: {
              slidesPerView: 2.3,
              spaceBetween: 46
            }
          }}
          className="!py-3 max-sm:!-mr-6 max-sm:!pr-6"
        >
          {fields.images.map((image, i) => {
            return (
              <Slide
                className="bg-site-alt cursor-pointer overflow-hidden group hover:bg-site-base !transition-all !duration-200 aspect-square rounded-[7px] shadow-site-shadow"
                key={i}
              >
                <LossePlaatjie
                  maxwidth={2000}
                  className="absolute group-hover:opacity-50 transition-all duration-200 -z-10 object-cover h-full w-full top-0 left-0 rounded-[7px]"
                  src={image}
                  onClick={() => openLightboxOnSlide(i + 1)}
                />
              </Slide>
            )
          })}
        </Slider>
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={fields.images.map((image) => image.mediaItemUrl)}
          slide={lightboxController.slide}
        />
      </div>
    </section>
  )
}
