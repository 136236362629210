import { type LosseBlogBinkReadableParams, useSearchParams, LosseBlogBink } from '@ubo/losse-sjedel'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import Checkboxlist from '~/components/elements/product-overview/CheckboxList'
import NoResults from '~/components/elements/product-overview/NoResults'
import Pagination from '~/components/elements/product-overview/Pagination'
import type { Page_Flexcontent_Flex_Posts, ProductItem as ProductItemType, RootQueryToPostConnection } from '~/graphql/types'
import { ProductItem } from './post-types/ProductItem'
import FlexContentHandler from '../FlexContentHandler'
import RangeFilter from '~/components/elements/product-overview/RangeFilter'
import useIsMobile from '~/hooks/useIsMobile'
import clsx from 'clsx'
import Select from '~/components/elements/product-overview/Select'

export const READABLE_PARAMS: LosseBlogBinkReadableParams & {
  price: string
} = {
  price: 'Prijs',
  filters: 'Filters',
  direction: 'Volgorde',
  sort: 'Sorteren',
  cursor: 'Pagina',
  query: 'Zoeken'
}

export const POSTS_LIMIT = 12

type ProductOverProps = Page_Flexcontent_Flex_Posts & {
  subdata: {
    Products: RootQueryToPostConnection
  }
}

export default function ProductOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="ProductOverview" className="section section--with-bg bg-site-grey">
      <LosseBlogBink readableParams={READABLE_PARAMS} useFormElement={false}>
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 xl:gap-14">
            <Header fields={fields as ProductOverProps} />
            <div className="lg:col-span-2">
              <Overview fields={fields as ProductOverProps} />
            </div>
          </div>
        </div>
      </LosseBlogBink>
    </section>
  )
}

function Header({ fields }: { fields: ProductOverProps }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()
  const [query, setQuery] = useState('')
  const init = useRef(false)
  const debouncedValue = useDebounce<string>(query, 500)

  useEffect(() => {
    if (!init.current) {
      init.current = true
      return
    }

    if (!debouncedValue) {
      if (searchParams.get(READABLE_PARAMS.query)) {
        searchParams.delete(READABLE_PARAMS.query)
        setSearchParams(searchParams, {
          state: {
            scroll: false
          }
        })
      }
      return
    }

    searchParams.set(READABLE_PARAMS.query, debouncedValue)
    setSearchParams(searchParams, {
      state: {
        scroll: false
      }
    })
    // eslint-disable-next-line
  }, [debouncedValue.toString()])

  return (
    <div className="max-lg:fixed max-lg:w-full max-lg:bottom-0 max-lg:left-0 z-50 lg:z-30">
      <div className={clsx('bg-white lg:rounded-[7px] shadow-site-shadow flex flex-col p-4 lg:p-10 gap-6')}>
        <div
          className="max-lg:text-center flex max-lg:justify-center items-center gap-3 max-lg:cursor-pointer relative"
          onClick={() => {
            if (isMobile) {
              setOpen(!open)
            }
          }}
        >
          {isMobile && !open && (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
              <path
                className="fill-site-base lg:fill-site-accent"
                d="M11.77 19q-.33 0-.55-.22q-.22-.22-.22-.55v-5.576L5.604 5.83q-.202-.27-.055-.55q.147-.28.47-.28h11.962q.323 0 .47.28q.147.282-.055.55L13 12.655v5.577q0 .329-.22.549q-.22.22-.55.22z"
              />
            </svg>
          )}
          <div className="lg:flex items-end lg:w-full justify-center lg:justify-between">
            <h2 className="text-3xl font-semibold text-site-base lg:text-site-accent">
              {open
                ? `${fields.subdata.Products.pageInfo.offsetPagination.total} resulta${
                    fields.subdata.Products.pageInfo.offsetPagination.total === 1 ? `at` : `ten`
                  }`
                : fields?.title}
            </h2>
            <div
              onClick={() => {
                // delete all searchparams
                searchParams.delete(READABLE_PARAMS.filters)
                searchParams.delete(READABLE_PARAMS.direction)
                searchParams.delete(READABLE_PARAMS.sort)
                searchParams.delete(READABLE_PARAMS.cursor)
                searchParams.delete(READABLE_PARAMS.query)
                searchParams.delete(READABLE_PARAMS.price)
                setSearchParams(searchParams, {
                  state: {
                    scroll: false
                  }
                })
              }}
              className={clsx('underline font-light cursor-pointer', !open && 'max-lg:hidden')}
            >
              Wis filters
            </div>
          </div>

          <svg
            className={clsx(!open && `hidden`, 'absolute right-2 top-0 bottom-0 my-auto')}
            onClick={() => setOpen(false)}
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275z"
            />
          </svg>
        </div>

        <div className={clsx(open ? `max-w-[400px] max-xs:w-full xs:min-w-[400px] mx-auto` : `max-lg:hidden`)}>
          <div className="flex flex-col gap-6">
            <div>
              <label className="text-lg font-semibold">Zoeken</label>

              <div className="mt-1">
                <div className="relative w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5"
                  >
                    <g clipPath="url(#clip0_799_5320)">
                      <path
                        d="M20.7608 20.1062L15.6358 14.9813C16.9097 13.4494 17.6772 11.4823 17.6772 9.33887C17.6772 4.4651 13.7122 0.5 8.83865 0.5C3.96499 0.5 0 4.4651 0 9.33887C0 14.2123 3.96499 18.1771 8.83865 18.1771C10.9819 18.1771 12.9492 17.4097 14.4811 16.1358L19.6062 21.2608C19.7656 21.4203 19.9746 21.5 20.1835 21.5C20.3924 21.5 20.6015 21.4203 20.7609 21.2608C21.0798 20.942 21.0798 20.4251 20.7608 20.1062ZM1.63294 9.33887C1.63294 5.3655 4.86539 2.13294 8.83865 2.13294C12.8118 2.13294 16.0441 5.3655 16.0441 9.33887C16.0441 13.3119 12.8118 16.5441 8.83865 16.5441C4.86539 16.5441 1.63294 13.3119 1.63294 9.33887Z"
                        fill="#1D1D1B"
                      />
                    </g>
                  </svg>
                  <input
                    type="text"
                    className="w-full !rounded-[7px] bg-site-grey border border-site-alt h-12 pl-10"
                    placeholder={fields?.filterPlaceholder || 'Bijv. Belcel'}
                    onChange={(event) => setQuery(event.target?.value)}
                    defaultValue={searchParams.get(READABLE_PARAMS.query)}
                  />
                </div>
              </div>
            </div>

            {fields?.filters
              ?.filter((f) => f?.parentDatabaseId === null)
              ?.map((filterCategory, index) => (
                <Fragment key={filterCategory?.databaseId}>
                  <Checkboxlist
                    field={filterCategory}
                    filters={fields?.filters?.filter((f) => f?.parentDatabaseId === filterCategory?.databaseId)}
                  />
                  {index === 0 && (
                    <RangeFilter
                      min={fields?.filterPrice?.from || 0}
                      max={fields?.filterPrice?.to || 10000}
                      slug="Prijs"
                      label="Prijs (€)"
                    />
                  )}
                </Fragment>
              ))}

            <div className="mt-8 pb-6 lg:hidden">
              <button
                type="button"
                onClick={() => {
                  setOpen(false)
                }}
                className="btn--base w-full !text-xl !py-3"
              >
                Toon {fields.subdata.Products.pageInfo.offsetPagination.total} resultaten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Overview({ fields }: { fields: ProductOverProps }) {
  const posts = fields.subdata.Products?.edges as unknown as { node: ProductItemType }[]
  return (
    <Fragment>
      {posts?.length > 0 && (
        <>
          <div className="grid relative grid-cols-2 xl:grid-cols-3 gap-2 xl:gap-[18px] max-sm:-mx-3">
            <div className="lg:absolute max-lg:col-span-2 px-4 -top-14 w-full flex sm:flex-row flex-col justify-between items-end sm:items-center">
              <div className="flex items-center gap-3">
                <h2 className="">{`${fields.subdata.Products.pageInfo.offsetPagination.total} resulta${
                  fields.subdata.Products.pageInfo.offsetPagination.total === 1 ? `at` : `ten`
                }`}</h2>
              </div>

              <Select name="Sorteren" options={['Prijs oplopend', 'Prijs aflopend', 'Laatst toegevoegd', 'Eerst toegevoegd']} />
            </div>
            {posts?.map((edge, index) => (
              <Fragment key={index}>
                <ProductItem fields={edge?.node} />

                {(index === 5 || (index === posts?.length - 1 && posts?.length < 6)) && (
                  <div className="col-span-2 xl:col-span-3 py-8 xl:py-12 remove-section">
                    {/* @ts-ignore */}
                    <FlexContentHandler prefix="fragment_FlexFragment" flex={fields?.element?.flex_fragment?.flex} />
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}

      {posts?.length === 0 && (
        <div>
          <NoResults />
          {/* @ts-ignore */}
          <FlexContentHandler prefix="fragment_FlexFragment" flex={fields?.element?.flex_fragment?.flex} />
        </div>
      )}

      <div className="flex justify-center mt-12">
        <Pagination total={fields?.subdata?.Products?.pageInfo?.offsetPagination?.total} limit={POSTS_LIMIT} />
      </div>
    </Fragment>
  )
}
