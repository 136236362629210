import clsx from 'clsx'
import useIsMobile from '~/hooks/useIsMobile'

export default function PageDownArrow({ className }: { className?: string }) {
  const isMobile = useIsMobile()

  return (
    <div
      onClick={() => {
        // scroll page down
        window.scrollTo({ top: isMobile ? window.innerHeight * 0.75 : window.innerHeight, behavior: 'smooth' })
      }}
      className={clsx(className && className, 'flex flex-col items-center gap-2 cursor-pointer')}
    >
      <div className="text-white font-bold text-xl">Scroll</div>
      <svg className="w-[26px] h-[45px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 45" fill="none">
        <g clipPath="url(#clip0_709_1404)">
          <path
            d="M-5.24521e-05 29.7735C-5.24521e-05 29.615 0.0620375 29.4588 0.181502 29.3377C0.385849 29.1314 0.709661 29.0783 0.974525 29.2092L9.12404 33.212L9.12404 0.635372C9.12482 0.284796 9.42427 0 9.79288 0C10.1615 0 10.4609 0.284796 10.4609 0.635372L10.4609 44.3639C10.4609 44.6502 10.2597 44.9006 9.96972 44.9768C9.6797 45.0523 9.37161 44.935 9.21835 44.689L0.0934763 30.0979C0.0306005 29.997 -0.00083828 29.8849 -0.00083828 29.7735H-5.24521e-05ZM9.12482 34.6427L2.43639 31.3575L9.12482 42.0526L9.12482 34.6434V34.6427Z"
            fill="white"
          />
          <path
            d="M15.5307 44.3639L15.5386 0.635372C15.5386 0.284796 15.838 0 16.2074 0C16.5768 0 16.8755 0.284796 16.8755 0.635372L16.87 33.2105L25.0258 29.2077C25.2906 29.0776 25.6144 29.1307 25.8188 29.337C26.0231 29.5433 26.0593 29.8542 25.906 30.0979L16.7733 44.689C16.6192 44.935 16.3119 45.0531 16.0219 44.9768C15.7319 44.9006 15.5307 44.6502 15.5307 44.3639ZM16.8692 34.6412L16.8676 42.0526L23.5631 31.3567L16.8692 34.6419V34.6412Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_709_1404">
            <rect width="45" height="26" fill="white" transform="matrix(0 1 -1 0 26 0)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
