import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { useEffect, useRef, useState } from 'react'
import { Thumbs } from 'swiper/modules'
import { SwiperSlide } from 'swiper/react'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import Slider from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Banner, Page_Flexcontent_Flex_Banner_Choices_Choices, ProductItem } from '~/graphql/types'
import type { Swiper as SwiperType } from 'swiper'
import Accordion from '~/components/elements/Accordion'
import useIsMobile, { useIsSM } from '~/hooks/useIsMobile'

export default function BannerProduct({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef<SwiperType>()
  const topRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()
  const isSM = useIsSM()
  const [height, setHeight] = useState(topRef.current?.clientHeight)
  const { page } = useLoaderData<{ page: ProductItem }>()
  const [links, setLinks] = useState(fields.links)
  const [values, setValues] = useState<
    {
      [key: string]: string
    }[]
  >([])

  useEffect(() => {
    setHeight(topRef.current?.clientHeight)
  }, [topRef.current?.clientHeight, isMobile, isSM])

  useEffect(() => {
    const newLinks = [...fields.links]
    newLinks.forEach((l) => {
      if (l.link.url.includes('/offerte/')) {
        l.link.url = `/offerte/?product=${page.title}${values.length > 0 ? ` (${values.map((v) => `${Object.keys(v)[0]}:${Object.values(v)[0]}`)})` : ``
          }`
      }
    })

    setLinks(newLinks)
  }, [values, fields.links, page.title])

  return (
    <section data-component="BannerProduct" className="section--pb section--with-bg bg-site-grey relative">
      <div style={{ height: height }} className="absolute w-full left-0 right-0 mx-auto h-full bg-site-alt top-0" />
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-7">
          <div className="lg:col-span-2">
            <div ref={topRef} className="bg-site-alt section relative section--with-bg">
              <div className="absolute top-4 lg:top-11">
                <Breadcrumbs />
              </div>
              <div className="lg:pr-8">
                <div className="relative">
                  <div
                    className="absolute left-4 md:-left-6 z-10 cursor-pointer top-1/2 translate-y-1/2"
                    onClick={() => swiperRef.current?.slidePrev()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="26" viewBox="0 0 45 26" fill="none">
                      <g clipPath="url(#clip0_912_1340)">
                        <path
                          d="M15.2265 26.0001C15.385 26.0001 15.5412 25.938 15.6623 25.8185C15.8686 25.6142 15.9217 25.2903 15.7908 25.0255L11.788 16.876L44.3646 16.876C44.7152 16.8752 45 16.5757 45 16.2071C45 15.8385 44.7152 15.5391 44.3646 15.5391L0.63612 15.5391C0.349827 15.5391 0.0994148 15.7403 0.0231705 16.0303C-0.0523262 16.3203 0.065033 16.6284 0.310959 16.7816L14.9021 25.9065C15.003 25.9694 15.1151 26.0008 15.2265 26.0008V26.0001ZM10.3573 16.8752L13.6425 23.5636L2.94738 16.8752H10.3566H10.3573Z"
                          fill="white"
                        />
                        <path
                          d="M0.63612 10.4695L44.3646 10.4617C44.7152 10.4617 45 10.1622 45 9.79283C45 9.42344 44.7152 9.12478 44.3646 9.12478L11.7895 9.13028L15.7923 0.97448C15.9224 0.709615 15.8693 0.385804 15.663 0.181457C15.4567 -0.02289 15.1458 -0.0590437 14.9021 0.0942165L0.310959 9.22695C0.065033 9.381 -0.0530739 9.6883 0.0231705 9.97832C0.0994148 10.2683 0.349827 10.4695 0.63612 10.4695ZM10.3588 9.13107L2.94738 9.13264L13.6433 2.43713L10.3581 9.13107H10.3588Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </div>
                  <div
                    className="absolute right-4 md:-right-6 z-10 cursor-pointer top-1/2 translate-y-1/2"
                    onClick={() => swiperRef.current?.slideNext()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="26" viewBox="0 0 45 26" fill="none">
                      <g clipPath="url(#clip0_912_1337)">
                        <path
                          d="M29.7735 26.0001C29.615 26.0001 29.4588 25.938 29.3377 25.8185C29.1314 25.6142 29.0783 25.2903 29.2092 25.0255L33.212 16.876L0.635372 16.876C0.284796 16.8752 0 16.5757 0 16.2071C0 15.8385 0.284796 15.5391 0.635372 15.5391L44.3639 15.5391C44.6502 15.5391 44.9006 15.7403 44.9768 16.0303C45.0523 16.3203 44.935 16.6284 44.689 16.7816L30.0979 25.9065C29.997 25.9694 29.8849 26.0008 29.7735 26.0008V26.0001ZM34.6427 16.8752L31.3575 23.5636L42.0526 16.8752H34.6434H34.6427Z"
                          fill="white"
                        />
                        <path
                          d="M44.3639 10.4695L0.635372 10.4617C0.284796 10.4617 0 10.1622 0 9.79283C0 9.42344 0.284796 9.12478 0.635372 9.12478L33.2105 9.13028L29.2077 0.97448C29.0776 0.709615 29.1307 0.385804 29.337 0.181457C29.5433 -0.02289 29.8542 -0.0590437 30.0979 0.0942165L44.689 9.22695C44.935 9.381 45.0531 9.6883 44.9768 9.97832C44.9006 10.2683 44.6502 10.4695 44.3639 10.4695ZM34.6412 9.13107L42.0526 9.13264L31.3567 2.43713L34.6419 9.13107H34.6412Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </div>
                  <Slider
                    modules={[Thumbs]}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    onBeforeInit={(swiper) => {
                      swiperRef.current = swiper
                    }}
                    loop
                  >
                    {fields?.images?.map((image: any, index: number) => (
                      <SwiperSlide key={index}>
                        <LossePlaatjie
                          loading="eager"
                          maxwidth={1000}
                          src={image}
                          className="h-[300px] md:h-[460px] object-cover rounded-lg"
                        />
                      </SwiperSlide>
                    ))}
                  </Slider>
                </div>

                <Slider
                  modules={[Thumbs]}
                  slideToClickedSlide={true}
                  watchSlidesProgress
                  onSwiper={setThumbsSwiper}
                  spaceBetween={15}
                  slidesPerView={4}
                  breakpoints={{
                    0: {
                      slidesPerView: 2
                    },
                    640: {
                      slidesPerView: 3
                    },
                    991: {
                      slidesPerView: 4
                    }
                  }}
                  className="mt-6 sm:mt-10"
                >
                  {fields?.images?.map((image: any, index: number) => (
                    <SwiperSlide key={index} className="cursor-pointer">
                      <LossePlaatjie loading="eager" maxwidth={500} src={image} className="h-[107px] object-cover rounded-lg" />
                    </SwiperSlide>
                  ))}
                </Slider>
                <div
                  data-component="ScrollBlock"
                  className="lg:absolute lg:hidden block mt-6 bg-white rounded-[7px] shadow-site-shadow p-5 sm:p-9"
                >
                  <h1 className="text-xl md:text-3xl text-site-base font-semibold">{fields?.title}</h1>
                  <p className="text-site-base font-light text-lg mb-4">{fields?.subtitle}</p>
                  <p className="flex items-center">
                    <span className="text-xl md:text-2xl text-site-accent font-semibold">
                      {page.recap.price?.toLocaleString('nl-NL', {
                        style: 'currency',
                        currency: 'EUR'
                      })}
                    </span>
                    <span className="ml-2 text-site-grey-alt font-normal">excl. btw</span>
                  </p>
                  <Content className="my-4 lg:my-7">{fields?.description}</Content>
                  <div className="sm:px-3 flex flex-wrap items-center gap-4 justify-start">
                    {fields?.choices?.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-black text-sm font-semibold mb-1">{item?.title}</p>
                        <select className="bg-[#F6F6F7] border border-[#a4a4a4] rounded w-[150px] h-[37px] pl-2">
                          {item?.choices?.map((option: Page_Flexcontent_Flex_Banner_Choices_Choices, index: number) => (
                            <option value={option?.title} key={index}>
                              {option?.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                  <Links items={links} className="px-3 mt-7 children-div:w-full children-a:w-full" />
                </div>
              </div>
            </div>

            <div className="div section--pt section--with-bg bg-site-grey">
              <div className="lg:pr-8">
                <Accordion fields={fields?.items} />
              </div>
            </div>
          </div>
          <aside className="col-span-1 max-lg:hidden z-20 section--pt">
            <div className="lg:sticky top-32">
              <div data-component="ScrollBlock" className="bg-white rounded-[7px] shadow-site-shadow p-9">
                <h1 className="text-xl md:text-3xl text-site-base font-semibold">{fields?.title}</h1>
                <p className="text-site-base font-light text-lg mb-4">{fields?.subtitle}</p>
                <p className="flex items-center">
                  <span className="text-xl md:text-2xl text-site-accent font-semibold">
                    {page.recap.price?.toLocaleString('nl-NL', {
                      style: 'currency',
                      currency: 'EUR'
                    })}
                  </span>
                  <span className="ml-2 text-site-grey-alt font-normal">excl. btw</span>
                </p>
                <Content className="my-4 lg:my-7">{fields?.description}</Content>
                <div className="flex lg:flex-col xl:flex-row items-center gap-4 justify-between">
                  {fields?.choices?.map((item: any, index: number) => (
                    <div key={index} className="relative">
                      <p className="text-black text-sm font-semibold mb-1">{item?.title}</p>
                      <select
                        onChange={(e) => {
                          const newValues = [...values]
                          newValues[index] = {
                            [item?.title]: e.target.value
                          }
                          setValues(newValues)
                        }}
                        className="bg-[#F6F6F7] border border-[#a4a4a4] rounded w-[150px] h-[37px] pl-2"
                      >
                        {item?.choices?.map((option: Page_Flexcontent_Flex_Banner_Choices_Choices, index: number) => (
                          <option key={index}>{option?.title}</option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
                <Links items={links} className="mt-7 children-div:w-full children-a:w-full" />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </section>
  )
}
