import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Arrow } from '~/components/elements/Arrow'
import type { ProductItem as Props } from '~/graphql/types'

export default function Product({ data, darktext = false }: { data: Props; darktext?: boolean }) {
  return (
    <div className="bg-site-alt overflow-hidden group hover:bg-site-base !transition-all relative !duration-200 h-[80px] sm:h-full w-full sm:aspect-square rounded-[7px] shadow-site-shadow">
      <LosseLink to={data?.uri} className="flex flex-row sm:flex-col sm:py-4 lg:py-6 sm:px-5 lg:px-7 sm:justify-end h-[80px] sm:h-full">
        {data?.featuredImage?.node && (
          <LossePlaatjie
            maxwidth={1000}
            className="sm:absolute group-hover:opacity-50 transition-all duration-200 z-10 object-cover h-[80px] sm:h-full w-[80px] sm:w-full top-0 left-0 rounded-l-[7px] sm:rounded-r-[7px]"
            src={data.featuredImage.node}
          />
        )}
        <div className="flex gap-3 w-full items-center sm:items-end relative z-20 justify-between px-4 sm:px-0">
          <span
            className={clsx(
              'text-base xs:text-lg sm:text-2xl lg:text-2xl ',
              darktext && data?.featuredImage?.node ? 'text-white sm:text-site-base' : 'text-white',
              '!leading-[1] font-semibold'
            )}
          >
            {data?.title}
          </span>
          <Arrow size="medium" />
        </div>
      </LosseLink>
    </div>
  )
}
