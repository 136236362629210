import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ProjectHighlighted from './ProjectHighlighted'
import ProjectOverview from './ProjectOverview'
import LinkSlider from './LinkSlider'
import ProductFurnishingOverview from './ProductFurnishingOverview'
import ServicesOverview from './ServicesOverview'
import ProductCategoryOverview from './ProductCategoryOverview'
import ProductSingleHighlighted from './ProductSingleHighlighted'
import ProjectFancy from './ProjectFancy'
import VacancyOverview from './VacancyOverview'
import ProductOverview from './ProductOverview'
import TeamHighlighted from './TeamHighlighted'
import BrandsOverview from './BrandsOverviews'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    project_highlighted: ProjectHighlighted,
    project_overview: ProjectOverview,
    project_fancy: ProjectFancy,
    link_slider: LinkSlider,
    product_furnishing_overview: ProductFurnishingOverview,
    product_single_highlighted: ProductSingleHighlighted,
    product_category_overview: ProductCategoryOverview,
    services_overview: ServicesOverview,
    vacancy_overview: VacancyOverview,
    product_overview: ProductOverview,
    team_highlighted: TeamHighlighted,
    brands_overview: BrandsOverview
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
