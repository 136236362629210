import { losseBlogBinkPrepare, losseLinkFragment, lossePlaatjieFragment } from '@ubo/losse-sjedel'
import type { LosseBlogBinkReadableParams, Query } from '@ubo/losse-sjedel'
import { pageFragment, postFragment, productFragment, projectFragment, vacancyFragment } from './Fragments'
import { POSTS_LIMIT } from '~/components/flex/Posts/ProductOverview'
import { PROJECT_LIMIT, PROJECT_READABLE_PARAMS } from '~/components/flex/Posts/ProjectOverview'

export const READABLE_PARAMS: LosseBlogBinkReadableParams & {
  price: string
} = {
  price: 'Prijs',
  filters: 'Filters',
  direction: 'Volgorde',
  sort: 'Sorteren',
  cursor: 'Pagina',
  query: 'Zoeken'
}

const Queries: Query[] = [
  {
    name: 'Header',
    raw: `
      Header: componentBy(componentId: 6) {
        header {
          menuHeader {
            link {
              ${losseLinkFragment}
            }
            submenu {
              link {
                ${losseLinkFragment}
              }
            }
          }
          sidemenu {
            menuitem {
              link {
                ${losseLinkFragment}
              }
            }
            image {
              ${lossePlaatjieFragment}
            }
            imagelink {
              ${losseLinkFragment}
            }
          }
          menuRight {
            link {
              ${losseLinkFragment}
            }
          }
        }
      }
    `
  },
  {
    name: 'Footer',
    raw: `
      Footer: componentBy(componentId: 5) {
        footer {
          projectfurnishing {
            link {
              ${losseLinkFragment}
            }
          }
          whatwedo {
            link {
              ${losseLinkFragment}
            }
          }
          general {
            link {
              ${losseLinkFragment}
            }
          }
          submenu {
            link {
              ${losseLinkFragment}
            }
          }
          link {
            ${losseLinkFragment}
          }
          copyright
        }
      }
    `
  },
  {
    name: 'ScrollProgress',
    type: 'root',
    raw: `
      ScrollProgress: componentBy(componentId: 501) {
        scrollprogress {
          pages {
            ... on ProductItem {
              databaseId
            }
            ... on Project {
              databaseId
            }
            ... on Page {
              databaseId
            }
            ... on Post {
              databaseId
            }
          }
        }
      }
    `
  },
  {
    name: 'GeneralInfo',
    type: 'root',
    raw: `
      GeneralInfo: componentBy(componentId: 96) {
        generalInfo {
          address
          city
          zipcode
          openingtimes {
            item
          }
          openingtimesextra
          maps
          phone {
            ${losseLinkFragment}
          }
          mail {
            ${losseLinkFragment}
          }
          instagram {
            ${losseLinkFragment}
          }
          linkedin {
            ${losseLinkFragment}
          }
          pinterest {
            ${losseLinkFragment}
          }
        }
      }
    `
  },
  {
    name: 'googlePlaces',
    type: 'root',
    raw: `
      googlePlaces: googlePlace {
        totalRating
        totalReviews
      }
    `
  },
  {
    name: 'Scripts',
    raw: `
      Scripts: remixSettings {
        headerScripts
        bodyScripts
      }
    `
  },
  {
    name: 'Projects',
    raw: (wpController, _, flexItem) => {
      if (flexItem.styletype !== 'project_overview') return null

      const { orderBy, order, where, condition } = losseBlogBinkPrepare({
        url: wpController.url,
        limit: PROJECT_LIMIT,
        readableParams: PROJECT_READABLE_PARAMS,
        defaultOrder: 'DESC',
        whereFilter: 'categoryName'
      })

      const page = Number(wpController.url.searchParams.get('Pagina')) || 1
      const _where = `${where}, offsetPagination: { size: ${PROJECT_LIMIT}, offset: ${(page - 1) * PROJECT_LIMIT} }`

      return `
        projecten(where: {orderby: {field: ${orderBy}, order: ${order}}, status: PUBLISH${_where}}${condition}) {
          edges {
            node {
              ${projectFragment}
            }
          }
          pageInfo {
            offsetPagination {
              total
              hasPrevious
              hasMore
            }
          }
        }
      `
    }
  },
  {
    name: 'ProductCategories',
    raw: (wpController, _, flexItem) => {
      if (flexItem.styletype !== 'product_category_overview') return null

      const { orderBy, order, where, condition } = losseBlogBinkPrepare({
        url: wpController.url,
        limit: 100,
        readableParams: READABLE_PARAMS,
        defaultOrder: 'DESC',
        whereFilter: 'categoryIds'
      })

      const page = Number(wpController.url.searchParams.get('Pagina')) || 1
      const _where = `${where}, offsetPagination: { size: ${100}, offset: ${(page - 1) * 100} }`

      return `
        producten(where: {orderby: {field: ${orderBy}, order: ${order}}, status: PUBLISH${_where}, parentIn: "null"}${condition}) {
          edges {
            node {
              ${productFragment}
            }
          }
          pageInfo {
            offsetPagination {
              total
              hasPrevious
              hasMore
            }
          }
        }
      `
    }
  },
  {
    name: 'Products',
    raw: (wpController, data, flexItem) => {
      if (flexItem.styletype !== 'product_overview') return null
      // @ts-ignore
      const currentPageId = data.Page.databaseId as number

      const { order, where, condition } = losseBlogBinkPrepare({
        url: wpController.url,
        limit: POSTS_LIMIT,
        readableParams: READABLE_PARAMS,
        defaultOrder: 'ASC',
        whereFilter: 'categoryIn'
      })

      const hasPrice = wpController.url.searchParams.has('Prijs')
      let priceQuery = ''

      if (hasPrice) {
        const priceRange = wpController.url.search
          ?.split('&')
          ?.filter((param) => param.includes('Prijs'))?.[0]
          ?.split('=')?.[1]
          ?.split('-')
        const price = { min: Number(priceRange[0] || 0), max: Number(priceRange[1] || 100000) }
        priceQuery = hasPrice ? `, range: {max: ${price.max}, min: ${price.min}}` : ``
      }

      const page = Number(wpController.url.searchParams.get('Pagina')) || 1
      let _where = `${where}, offsetPagination: { size: ${POSTS_LIMIT}, offset: ${(page - 1) * POSTS_LIMIT} }`

      let newOrderBy = 'PRICE'
      let newOrder = order

      if (wpController.url.searchParams.has('Sorteren')) {
        const sort = wpController.url.searchParams.get('Sorteren')

        newOrderBy = sort.includes('Prijs') ? 'PRICE' : 'DATE'

        switch (sort) {
          case 'Eerst toegevoegd':
            newOrder = 'ASC'
            break
          case 'Laatst toegevoegd':
            newOrder = 'DESC'
            break
          case 'Prijs aflopend':
            newOrder = 'DESC'
            break
          case 'Prijs oplopend':
            newOrder = 'ASC'
            break
          default:
            break
        }
      }

      if (newOrderBy === 'PRICE' && !_where.includes('range') && !priceQuery) {
        _where = `${_where}, range: {max: 100000, min: 0}`
      }

      return `
        producten(where: {orderby: {field: ${newOrderBy}, order: ${newOrder}}, status: PUBLISH${_where}, parentNotIn: "null", parent: ${currentPageId}${priceQuery} }${condition}) {
          edges {
            node {
              ${productFragment}
            }
          }
          pageInfo {
            offsetPagination {
              total
              hasPrevious
              hasMore
            }
          }
        }
      `
    }
  },
  {
    name: 'Search',
    raw: `
      query ($search: String!) {
        Search: contentNodes(
          where: {search: $search}
        ) {
          pageInfo {
            offsetPagination {
              total
              hasPrevious
              hasMore
            }
          }
          edges {
            cursor
            node {
              contentTypeName
              ... on Page {
                ${pageFragment}
              }
              ... on Post {
                ${postFragment}
              }
              ... on Project {
                ${projectFragment}
              }
              ... on ProductItem {
                ${productFragment}
              }
              ... on Vacatures {
                ${vacancyFragment}
              }
            }
          }
        }
      }
    `
  }
]

export default Queries
