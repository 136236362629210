import { EffectCards, Mousewheel, Navigation } from 'swiper/modules'
import Links from '~/components/elements/Links'
import Slider, { Slide } from '~/components/elements/Slider'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Project from './post-types/Project'
import { useEffect } from 'react'

export default function ProjectFancy({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const itemLength = fields.projects?.length || 0
  const initialSlideIndex = Math.floor(itemLength / 2)

  // When component is loaded for the first time, slide the slider to the next slide
  // This is to prevent the first slide from being cut off
  // This is a hacky solution, but it works
  useEffect(() => {
    setTimeout(() => {
      const slider = document.querySelector('.custom-contentwithcarousel-button-next')
      slider?.dispatchEvent(new Event('click'))
    }, 1000)
  }, [])

  if (!fields.projects) return null

  const items = fields.projects
  items.push(items[0])
  items.push(items[1])
  items.push(items[2])

  return (
    <section data-component="ProjectFancy" className="relative overflow-hidden">
      <div className="container section--pt">
        <Subtitle>{fields?.subtitle}</Subtitle>
        <Title>{fields?.title}</Title>
      </div>
      <div className="max-w-[2000px] mx-auto relative sm:mx-7">
        <div className="custom-contentwithcarousel-button-prev absolute left-4 lg:left-32 z-10 cursor-pointer top-1/2 translate-y-1/2">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="26" viewBox="0 0 45 26" fill="none">
            <g clipPath="url(#clip0_709_1706)">
              <path
                d="M15.2265 26.0001C15.385 26.0001 15.5412 25.938 15.6623 25.8185C15.8686 25.6142 15.9217 25.2903 15.7908 25.0255L11.788 16.876L44.3646 16.876C44.7152 16.8752 45 16.5757 45 16.2071C45 15.8385 44.7152 15.5391 44.3646 15.5391L0.63612 15.5391C0.349827 15.5391 0.0994148 15.7403 0.0231705 16.0303C-0.0523262 16.3203 0.065033 16.6284 0.310959 16.7816L14.9021 25.9065C15.003 25.9694 15.1151 26.0008 15.2265 26.0008V26.0001ZM10.3573 16.8752L13.6425 23.5636L2.94738 16.8752H10.3566H10.3573Z"
                fill="#393C42"
              />
              <path
                d="M0.63612 10.4695L44.3646 10.4617C44.7152 10.4617 45 10.1622 45 9.79283C45 9.42344 44.7152 9.12478 44.3646 9.12478L11.7895 9.13028L15.7923 0.97448C15.9224 0.709615 15.8693 0.385804 15.663 0.181457C15.4567 -0.02289 15.1458 -0.0590437 14.9021 0.0942165L0.310959 9.22695C0.065033 9.381 -0.0530739 9.6883 0.0231705 9.97832C0.0994148 10.2683 0.349827 10.4695 0.63612 10.4695ZM10.3588 9.13107L2.94738 9.13264L13.6433 2.43713L10.3581 9.13107H10.3588Z"
                fill="#393C42"
              />
            </g>
            <defs>
              <clipPath id="clip0_709_1706">
                <rect width="45" height="26" fill="white" transform="matrix(-1 0 0 1 45 0)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="custom-contentwithcarousel-button-next absolute right-4 lg:right-32 z-10 cursor-pointer top-1/2 translate-y-1/2">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="26" viewBox="0 0 45 26" fill="none">
            <g clipPath="url(#clip0_709_1703)">
              <path
                d="M29.7735 26.0001C29.615 26.0001 29.4588 25.938 29.3377 25.8185C29.1314 25.6142 29.0783 25.2903 29.2092 25.0255L33.212 16.876L0.635372 16.876C0.284796 16.8752 0 16.5757 0 16.2071C0 15.8385 0.284796 15.5391 0.635372 15.5391L44.3639 15.5391C44.6502 15.5391 44.9006 15.7403 44.9768 16.0303C45.0523 16.3203 44.935 16.6284 44.689 16.7816L30.0979 25.9065C29.997 25.9694 29.8849 26.0008 29.7735 26.0008V26.0001ZM34.6427 16.8752L31.3575 23.5636L42.0526 16.8752H34.6434H34.6427Z"
                fill="#393C42"
              />
              <path
                d="M44.3639 10.4695L0.635372 10.4617C0.284796 10.4617 0 10.1622 0 9.79283C0 9.42344 0.284796 9.12478 0.635372 9.12478L33.2105 9.13028L29.2077 0.97448C29.0776 0.709615 29.1307 0.385804 29.337 0.181457C29.5433 -0.02289 29.8542 -0.0590437 30.0979 0.0942165L44.689 9.22695C44.935 9.381 45.0531 9.6883 44.9768 9.97832C44.9006 10.2683 44.6502 10.4695 44.3639 10.4695ZM34.6412 9.13107L42.0526 9.13264L31.3567 2.43713L34.6419 9.13107H34.6412Z"
                fill="#393C42"
              />
            </g>
            <defs>
              <clipPath id="clip0_709_1703">
                <rect width="45" height="26" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Slider
          mousewheel={{
            forceToAxis: true
          }}
          breakpoints={{
            0: {
              slidesPerView: 1.5
            },
            1024: {
              slidesPerView: 1.785
            }
          }}
          modules={[EffectCards, Mousewheel, Navigation]}
          effect="cards"
          cardsEffect={{
            perSlideRotate: 0,
            perSlideOffset: 30,
            slideShadows: false,
            rotate: false
          }}
          navigation={{
            prevEl: '.custom-contentwithcarousel-button-prev',
            nextEl: '.custom-contentwithcarousel-button-next'
          }}
          initialSlide={initialSlideIndex}
          centeredSlides
          loop
          className="!py-6 sm:!py-12 three-visible"
        >
          {items?.map((project, index) => {
            const projectInfo = project

            return (
              <Slide key={index} className="relative !h-auto drop-shadow-site-shadow">
                <Project data={projectInfo} customHeight="h-[350px] md:h-[400px] lg:h-[590px]" />
              </Slide>
            )
          })}
        </Slider>
      </div>

      <div className="flex justify-center container section--pb">
        <Links items={fields?.links} />
      </div>
    </section>
  )
}
