import { useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import useIsMobile from '~/hooks/useIsMobile'
import { type Category } from '~/graphql/types'

const MAX_ITEMS = 6

export default function Checkboxlist({ field, filters, className }: { field: Category; filters: Category[]; className?: string }) {
  const [searchParams] = useSearchParams()
  const [showMore, setShowMore] = useLocalStorage(`show-more-${field}`, false)
  const isMobile = useIsMobile()
  const [_isOpen, setOpen] = useState(!isMobile)
  const isOpen = isMobile ? _isOpen : true
  const selected = searchParams.get('Filters')?.split(',') || []

  return (
    <div className={clsx(className)}>
      <label
        role={isMobile ? 'button' : undefined}
        className="text-lg font-semibold mb-2 block"
        onClick={() => {
          if (isMobile) setOpen(!isOpen)
        }}
      >
        {field.name}
      </label>
      <div>
        <div className="max-lg:pb-4">
          {filters
            .filter((f) => f.parentDatabaseId === field.databaseId)
            .map((f) => f.databaseId.toString())
            .filter((f) => selected.includes(f)).length > 0 && (
            <div>
              <ul className="flex flex-col gap-3 max-lg:mt-2 mb-2">
                {selected.map((s) => {
                  const f = filters.filter((f) => f.databaseId.toString() === s)[0]

                  if (!f) return null

                  return <Option bucket={f} field={field} key={s} />
                })}
              </ul>
              <div className="w-full h-[1px] bg-site-alt my-3" />
            </div>
          )}
          <ul className="flex flex-col gap-3 max-lg:mt-2">
            {filters
              .filter((bucket) => !selected.includes(bucket.databaseId.toString()))
              .filter((_, index) => showMore || index < MAX_ITEMS)
              .map((bucket) => (
                <Option bucket={bucket} field={field} key={bucket.databaseId} />
              ))}
          </ul>
          {filters?.length > MAX_ITEMS && (
            <button
              type="button"
              onClick={() => {
                setShowMore(!showMore)
              }}
              className="mt-4 text-sm font-bold flex items-center gap-1 btn--psueod--link btn--base--link"
            >
              {showMore ? 'Toon minder' : 'Toon meer'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                className={clsx(showMore && 'rotate-180', !showMore && 'top-[2px]', 'w-5 h-5 relative transition-all')}
              >
                <path
                  d="M14.812 6.49955C14.7384 6.42535 14.6509 6.36646 14.5544 6.32627C14.4579 6.28607 14.3544 6.26538 14.2499 6.26538C14.1454 6.26538 14.042 6.28607 13.9455 6.32627C13.849 6.36646 13.7614 6.42535 13.6879 6.49955L10.062 10.1254C9.98842 10.1996 9.90086 10.2585 9.80439 10.2987C9.70792 10.3389 9.60444 10.3596 9.49994 10.3596C9.39543 10.3596 9.29195 10.3389 9.19548 10.2987C9.09901 10.2585 9.01145 10.1996 8.93785 10.1254L5.31202 6.49955C5.23842 6.42535 5.15086 6.36646 5.05439 6.32627C4.95792 6.28607 4.85445 6.26538 4.74994 6.26538C4.64543 6.26538 4.54195 6.28607 4.44548 6.32627C4.34901 6.36646 4.26145 6.42535 4.18785 6.49955C4.0404 6.64788 3.95764 6.84853 3.95764 7.05768C3.95764 7.26682 4.0404 7.46747 4.18785 7.6158L7.8216 11.2495C8.26692 11.6943 8.87056 11.9441 9.49994 11.9441C10.1293 11.9441 10.733 11.6943 11.1783 11.2495L14.812 7.6158C14.9595 7.46747 15.0422 7.26682 15.0422 7.05768C15.0422 6.84853 14.9595 6.64788 14.812 6.49955Z"
                  fill="#1D1D1B"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

function Option({ bucket, field }: { bucket: Category; field: Category }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <li key={bucket.databaseId}>
      <label
        htmlFor={bucket.databaseId.toString()}
        className="text-sm flex items-center gap-[18px] hover:cursor-pointer group hover:bg-site-grey-light/50 rounded-md"
        title={`${bucket.name}`}
      >
        <input
          id={bucket.databaseId.toString()}
          type="checkbox"
          onChange={() => {
            const currentParams = searchParams.get('Filters')?.split(',')

            if (currentParams?.includes(bucket.databaseId.toString())) {
              searchParams.set('Filters', currentParams.filter((param) => param !== bucket.databaseId.toString()).join(','))
              if (searchParams.get('Filters') === '') searchParams.delete('Filters')
            } else {
              currentParams?.push(bucket.databaseId.toString())
              searchParams.set('Filters', currentParams ? currentParams.join(',') : bucket.databaseId.toString())
            }

            setSearchParams(searchParams, {
              replace: true,
              state: {
                scroll: false
              }
            })
          }}
          className="hidden peer"
          defaultChecked={searchParams.get('Filters')?.split(',').includes(bucket.databaseId.toString())}
        />
        <div className="w-4 h-4 peer-checked:bg-site-accent [&_>_svg]:hidden peer-checked:[&_>_svg]:block bg-white border border-site-alt flex--center" />
        <span className="group-hover:underline line-clamp-1">{bucket.name}</span>
      </label>
    </li>
  )
}
