import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import type { ProductItem } from '~/graphql/types'

export function ProductItem({ fields }: { fields: ProductItem }) {
  const priceInclBtw = fields?.recap?.price * 1.21

  return (
    <LosseLink to={fields?.uri} className="bg-white rounded-[7px] shadow-site-shadow pt-1 sm:pt-2  flex flex-col justify-between">
      <div className="flex flex-col gap-1">
        <div className="w-full h-[160px] sm:h-[220px] xl:h-[260px] relative">
          {fields?.featuredImage?.node ? (
            <LossePlaatjie
              className="w-full h-full absolute object-contain left-0 top-0"
              maxwidth={500}
              src={fields?.featuredImage?.node}
            />
          ) : (
            <div className="flex h-full justify-center items-center">
              <img className="w-fit" alt="placeholder" src="/icons/placeholder.svg" />
            </div>
          )}
        </div>
        <div className="flex flex-col px-4">
          <div className="text-sm font-light text-center">
            {fields?.categories?.edges?.filter((e) => e?.node?.parentDatabaseId === 32)?.[0]?.node?.name || 'TOC'}
          </div>
          <div className="font-bold text-center">{fields?.title}</div>
        </div>
      </div>

      <div className="px-3">
        <div className="font-bold text-xl text-site-accent text-center max-sm:flex max-sm:flex-col ">
          {fields?.recap?.price?.toLocaleString('nl-NL', {
            style: 'currency',
            currency: 'EUR'
          })}
          *<span className="text-site-grey-alt font-light pl-2 text-sm">excl. btw</span>
        </div>
        <div className="font-bold text-sm text-site-base text-center max-sm:flex max-sm:flex-col ">
          {priceInclBtw?.toLocaleString('nl-NL', {
            style: 'currency',
            currency: 'EUR'
          })}
          <span className="text-site-grey-alt font-light pl-2 text-sm">incl. btw</span>
        </div>

        <Button to={fields?.uri} className="w-full mt-6 max-sm:!py-1 " buttonstyle="base_outlined" icons="arrow">
          Bekijk
        </Button>
        <div className="text-sm text-center py-4">*Prijzen excl. levering en montage</div>
      </div>
    </LosseLink>
  )
}
