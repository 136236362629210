import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentAdvice({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentAdvice" className="section section--with-bg bg-site-grey">
      <div className="container">
        <div className="max-w-screen-lg mx-auto sm:pt-3">
          <Title size={fields.title.length > 50 ? 'small' : 'medium'} className="md:text-center">
            {fields.title}
          </Title>
          <Content className="max-md:children-p:!text-left mt-4 lg:mt-10 md:px-20">{fields.description}</Content>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 sm:mt-10 lg:mt-20 gap-4 sm:gap-6 lg:gap-9">
            {fields.items.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-[7px] flex flex-col justify-between gap-4 lg:gap-6 shadow-site-shadow p-6 sm:p-6 lg:p-11"
              >
                <div className="flex flex-col lg:gap-6 gap-4">
                  <h3 className="font-semibold text-2xl lg:text-3xl">{item.title}</h3>
                  <Content>{item.description}</Content>
                </div>

                <div className="mt-2">
                  <Button
                    buttonstyle={item.buttonstyle}
                    to={item.link.url}
                    target={item.link.target}
                    className={clsx(index === 0 && 'btn--accent', index === 1 && 'btn--base-outlined')}
                  >
                    {item.link.title}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
