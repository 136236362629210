import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" className="section">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-10 lg:gap-20">
          <div className="flex flex-col justify-center">
            <Subtitle>{fields.subtitle}</Subtitle>
            <Title className="sm:mt-3" size="small">
              {fields.title}
            </Title>
            <LossePlaatjie
              className="object-cover w-full mx-auto sm:hidden rounded-[7px] mt-6 sm:mt-10 shadow-site-shadow lg:w-[580px] lg:h-[580px]"
              maxwidth={800}
              src={fields.image}
            />
            <Content className="mt-6 sm:mt-10">{fields.description}</Content>
            {fields.links && (
              <div className="mt-6 sm:mt-12">
                <Links items={fields.links} />
              </div>
            )}
          </div>
          <div className={clsx(fields.position === 'left' && 'lg:-order-1', 'flex max-sm:hidden max-lg:justify-center items-center')}>
            <LossePlaatjie
              className="object-cover rounded-[7px] shadow-site-shadow w-full lg:w-[580px] lg:h-[580px]"
              maxwidth={800}
              src={fields.image}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
