import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import { Title } from '~/components/elements/Title'
import { Subtitle } from '~/components/elements/Subtitle'
import Links from '~/components/elements/Links'
import clsx from 'clsx'

const svg_color_code = '#393C42'

export default function ContentIconImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentIconImage" className="bg-site-base section section--with-bg">
      <div className="container flex justify-stretch max-md:flex-col md:flex-row max-md:gap-6">
        <div className="md:basis-1/2 my-auto max-md:self-center">
          {fields?.image && (
            <div className="max-md:w-[250px] md:w-[350px] lg:w-[450px] max-md:h-[250px] md:h-[350px] lg:h-[450px] overflow-hidden max-md:mb-4 relative">
              <LossePlaatjie
                loading="eager"
                className={clsx(
                  fields.imageposition === 'left' && 'object-left',
                  fields.imageposition === 'right' && 'object-right',
                  fields.imageposition === 'bottom' && 'object-bottom',
                  fields.imageposition === 'top' && 'object-top',
                  fields.imageposition === 'center' && 'object-center',
                  'w-full p-[1px] h-full object-cover'
                )}
                src={fields.image}
                maxwidth={2000}
              />
              <svg className="absolute top-0 left-0" version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                <g fill={svg_color_code}>
                  <path d="M385.1,593.9h-55.7V469.1h53.8c53.7,0,91.7-42.3,93.7-91.5c0.3-5,0-12.2-0.4-16.6c-4.9-54.1-10.4-91-16.1-138.7c-1.4-11.6-2.7-16.8-4.6-23.5c-11.6-43-43.7-68-100-68H244.2c-56.3,0-88.3,25-100,68c-1.8,6.7-3.2,11.9-4.6,23.5c-5.7,47.7-11.2,84.6-16.1,138.7c-0.4,4.5-0.7,11.7-0.4,16.6c2.1,49.3,40,91.5,93.7,91.5h53.8v124.8h-55.7C96.7,593.9,0,497.2,0,379.1V600h600V379C600,497.2,503.3,593.9,385.1,593.9z" />
                  <path d="M0,0v221C0,102.8,96.7,6.1,214.9,6.1h170.3C503.3,6.1,600,102.8,600,221V0H0z" />
                </g>
              </svg>
            </div>
          )}
        </div>
        <div className="md:basis-1/2 flex flex-col justify-center">
          <Subtitle type="dark">{fields.subtitle}</Subtitle>
          <Title type="dark">{fields.title}</Title>
          {fields?.description && <Content className="children-p:text-white pt-5">{fields.description}</Content>}
          {fields?.links && (
            <div className="pt-7 lg:pt-12">
              <Links items={fields.links} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
