import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { FormDuufField, FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import useGeneralInfo from '~/hooks/useGeneralInfo'
import Content from '~/components/elements/Content'
import { Fragment } from 'react'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const info = useGeneralInfo()
  return (
    <Fragment>
      <section data-component="FormContact-Banner" className="bg-site-alt">
        <div className="container">
          <div className="flex flex-col gap-7 lg:gap-11 pb-24 sm:pb-24 lg:pb-24 pt-6 sm:pt-11">
            <Breadcrumbs />
            <h1 className="text-4xl sm:text-5xl xl:text-6xl pl-6 lg:pl-16 text-white font-semibold">{fields.title}</h1>
          </div>
        </div>
      </section>
      <section data-component="FormContact-Content" className="bg-site-grey section section--with-bg">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 max-lg:gap-10">
            <div className="flex flex-col gap-8 lg:gap-14">
              <div className="flex flex-col gap-3 sm:gap-5">
                <div className="font-semibold text-xl sm:text-2xl">Projectstudio/showroom</div>
                <div className="text-base sm:text-lg">
                  <div>TOC Kantoorinrichting*</div>
                  <div>{info.address}</div>
                  <div>{`${info.zipcode} ${info.city}`}</div>
                </div>
                <a href={info.mail.url} className="text-base sm:text-lg hover:underline flex items-center gap-3">
                  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="01 align center">
                      <path
                        id="Vector"
                        d="M21 0.000244141H3C2.20435 0.000244141 1.44129 0.316315 0.87868 0.878924C0.31607 1.44153 0 2.20459 0 3.00024L0 22.0002H24V3.00024C24 2.20459 23.6839 1.44153 23.1213 0.878924C22.5587 0.316315 21.7956 0.000244141 21 0.000244141ZM3 2.00024H21C21.2652 2.00024 21.5196 2.1056 21.7071 2.29314C21.8946 2.48067 22 2.73503 22 3.00024V3.66724L14.122 11.5462C13.5584 12.1076 12.7954 12.4227 12 12.4227C11.2046 12.4227 10.4416 12.1076 9.878 11.5462L2 3.66724V3.00024C2 2.73503 2.10536 2.48067 2.29289 2.29314C2.48043 2.1056 2.73478 2.00024 3 2.00024ZM2 20.0002V6.50024L8.464 12.9602C9.40263 13.8965 10.6743 14.4223 12 14.4223C13.3257 14.4223 14.5974 13.8965 15.536 12.9602L22 6.50024V20.0002H2Z"
                        fill="#42BD3B"
                      />
                    </g>
                  </svg>

                  {info.mail.title}
                </a>
                <a href={info.phone.url} className="text-base sm:text-lg hover:underline flex items-center gap-3">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="fi-rs-phone-call" clipPath="url(#clip0_881_2853)">
                      <path
                        id="Vector"
                        d="M14.2002 16.261C11.2886 15.0282 8.97325 12.7075 7.7472 9.793L11.1472 6.387L4.8872 0.122L1.7162 3.292C1.1662 3.84514 0.731342 4.50186 0.436795 5.22415C0.142247 5.94645 -0.00614821 6.71998 0.000195101 7.5C0.000195101 14.748 9.25219 24 16.5002 24C17.2801 24.0068 18.0536 23.8586 18.7758 23.5641C19.498 23.2695 20.1545 22.8344 20.7072 22.284L23.8782 19.113L17.6132 12.848L14.2002 16.261ZM19.2922 20.87C18.925 21.234 18.489 21.5214 18.0097 21.7154C17.5304 21.9094 17.0172 22.0061 16.5002 22C10.2672 22 2.0002 13.733 2.0002 7.5C1.99431 6.98283 2.09116 6.46964 2.28514 5.99019C2.47911 5.51074 2.76635 5.07458 3.1302 4.707L4.8872 2.95L8.3242 6.387L5.38919 9.322L5.63419 9.936C6.35544 11.8653 7.48296 13.6171 8.94035 15.0725C10.3977 16.528 12.1509 17.6533 14.0812 18.372L14.6872 18.603L17.6132 15.676L21.0502 19.113L19.2922 20.87ZM14.0002 2V0C16.6515 0.0029116 19.1933 1.05742 21.068 2.93215C22.9428 4.80688 23.9973 7.34873 24.0002 10H22.0002C21.9978 7.879 21.1542 5.84555 19.6544 4.34578C18.1546 2.846 16.1212 2.00238 14.0002 2ZM14.0002 6V4C15.591 4.00159 17.1162 4.63424 18.2411 5.75911C19.366 6.88399 19.9986 8.40919 20.0002 10H18.0002C18.0002 8.93913 17.5788 7.92172 16.8286 7.17157C16.0785 6.42143 15.0611 6 14.0002 6Z"
                        fill="#42BD3B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_881_2853">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {info.phone.title}
                </a>
              </div>
              <div className="flex flex-col gap-3 sm:gap-5">
                <div className="font-semibold text-xl sm:text-2xl">Openingstijden</div>
                <div className="text-base sm:text-lg">
                  <table>
                    <tbody>
                      {info?.openingtimes?.map((item, i) => (
                        <tr key={i}>
                          <td>{item?.item}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <p className="mt-4">{info.openingtimesextra}</p>
                </div>
                <div className="md:w-4/6">
                  <p>
                    *Deze website toont producten en diensten die worden aangeboden door TOC Eemland B.V. (KVK: 82913285) en TOC Cabines
                    B.V. (KVK: 91504155)
                  </p>
                </div>
              </div>
            </div>
            <div className="relative max-lg:-order-1 -mt-24 lg:-mt-60  p-4 sm:p-8 lg:py-12 lg:px-14 bg-white rounded-[7px] shadow-site-shadow">
              <Form
                data={fields.form}
                renderLoading={() => <Loading />}
                renderStatus={({ content }) => <Content>{content}</Content>}
                privacyUrl="/privacy-policy"
                privacyUrlPlacement="privacy verklaring"
                generate={false}
                scrollToConfirm={false}
              >
                <div className="flex flex-col gap-6 sm:gap-10">
                  <h3 className="font-semibold text-2xl sm:text-3xl">{fields.subtitle}</h3>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-1">
                        <FormDuufField id={12} />
                      </div>
                      <div className="col-span-1">
                        <FormDuufField id={13} />
                      </div>
                    </div>
                    <FormDuufGroup hideIds={[12, 13]} />
                    <FormDuufSubmit />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
