import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTitle({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentTitle" className="section section--with-bg bg-site-grey">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-20 max-w-[1070px] mx-auto">
          <div>
            <Subtitle>{fields.subtitle}</Subtitle>
            <Title className="!leading-[1.2] sm:mt-3" size="small">
              {fields?.title}
            </Title>
          </div>
          <div>
            <Content className="content">{fields?.description}</Content>
            {fields.links && (
              <div className="sm:mt-14 mt-10">
                <Links items={fields.links} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
