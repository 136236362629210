import { lossePlaatjieFragment } from '@ubo/losse-sjedel'

export const teamlidFragment = `
  __typename
  title
  databaseId
  recap {
    description
    image {
      ${lossePlaatjieFragment}
    }
    phoneNumber
    emailAddress
  }
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
`

export const postFragment = `
  __typename
  title
  uri
  databaseId
  status
  recap {
    excerpt
  }
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
  categories {
    edges {
      node {
        name
        databaseId
      }
    }
  }
`

export const merkFragment = `
  __typename
  title
  uri
  parentDatabaseId
  databaseId
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
`

export const productFragment = `
  __typename
  title
  uri
  databaseId
  recap {
    price
  }
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
  categories {
    edges {
      node {
        name
        databaseId
        parentDatabaseId
      }
    }
  }
  parentDatabaseId
`

export const projectFragment = `
  __typename
  title
  uri
  databaseId
  categories {
    edges {
      node {
        name
        databaseId
      }
    }
  }
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
`

export const pageFragment = `
  __typename
  title
  uri
  databaseId
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
`

export const vacancyFragment = `
  __typename
  title
  uri
  databaseId
  featuredImage {
    node {
      ${lossePlaatjieFragment}
    }
  }
  recap {
    education
    salary
    time
  }
`
