import Content from '~/components/elements/Content'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUsps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUsps" className="section section--with-bg bg-site-alt">
      <div className="container">
        <div className="flex justify-center">
          <Subtitle type="dark">{fields.subtitle}</Subtitle>
        </div>
        <Title size="small" className="text-center sm:mt-3" type="dark">
          {fields.title}
        </Title>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-10 lg:mt-16 gap-4 lg:gap-10">
          {fields.items.map((item, index) => (
            <div
              key={index}
              className="bg-site-silver max-sm:w-full max-md:w-fit max-md:min-w-[250px] max-md:mx-auto rounded-[7px] shadow-site-shadow p-6 lg:px-10 lg:pt-10 lg:pb-12"
            >
              <h3 className="text-center font-semibold text-2xl lg:text-3xl">{item.title}</h3>
              <Content className="mt-3 lg:mt-4">{item.description}</Content>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
