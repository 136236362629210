import clsx from 'clsx'
import useIsVisible from '~/hooks/useIsVisible'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {}

export default function AnimateInView({ className, children, ...rest }: Props) {
  const [ref, isVisible] = useIsVisible()

  return (
    <div ref={ref} className={clsx(isVisible ? 'opacity-100 animate-fade-up' : 'opacity-0', 'animate-once', className)} {...rest}>
      {children}
    </div>
  )
}
