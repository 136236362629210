import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useGeneralInfo from '~/hooks/useGeneralInfo'

export default function ContentGoogleMaps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const info = useGeneralInfo()

  return (
    <section data-component="ContentGoogleMaps" className="bg-site-grey section section--with-bg">
      <div className="container relative">
        <iframe
          className="relative lg:-left-[5%] w-full lg:w-[110%] rounded-[7px] shadow-sm"
          title="google-maps"
          src={info.maps}
          width="100%"
          height="420"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </section>
  )
}
