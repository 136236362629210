import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { FormDuufField, FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import Content from '~/components/elements/Content'
import { Fragment, useState } from 'react'
import { PersonCTA } from '~/components/elements/PersonCTA'
import { useSearchParams } from '@ubo/losse-sjedel'

export default function FormPersonWithBanner({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [searchParams] = useSearchParams()
  const [currentPerson] = useState(Math.floor(Math.random() * fields.teammembers.length))

  return (
    <Fragment>
      <section data-component="FormPersonWithBanner-Banner" className="bg-site-alt">
        <div className="container">
          <div className="flex flex-col gap-7 lg:gap-11 pb-24 sm:pb-24 lg:pb-24 pt-6 sm:pt-11">
            <Breadcrumbs />
            <h1 className="text-4xl sm:text-5xl xl:text-6xl pl-6 lg:pl-16 text-white font-semibold">
              {fields.title.split('|').map((t, i) => (
                <Fragment key={i}>
                  {t}
                  <br />
                </Fragment>
              ))}
            </h1>
          </div>
        </div>
      </section>
      <section data-component="FormPersonWithBanner-Content" className="bg-site-grey section section--with-bg">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-5 max-lg:gap-10">
            <div className="lg:col-span-2 xl:pl-16 flex flex-col xm:flex-row max-xm:items-center max-lg:justify-center lg:flex-col gap-8 lg:gap-14">
              <div className="hidden lg:flex flex-col gap-3 sm:gap-5 xm:max-w-[300px] lg:max-w-[400px]">
                <Content>{fields.description}</Content>
              </div>
              <div className="flex flex-col gap-3 sm:gap-5">
                <PersonCTA fields={{ relation: fields.teammembers[currentPerson], title: fields.teammembertitle }} />
              </div>
            </div>
            <div className="lg:col-span-3 relative max-lg:-order-1 -mt-24 lg:-mt-60  p-4 sm:p-8 lg:py-12 lg:px-14 bg-white rounded-[7px] shadow-site-shadow">
              <Form
                data={fields.form}
                renderLoading={() => <Loading />}
                renderStatus={({ content }) => <Content>{content}</Content>}
                privacyUrl="/privacy-policy"
                scrollToConfirm={false}
                privacyUrlPlacement="privacy verklaring"
                generate={false}
                defaultFields={{
                  input_13: searchParams.get('product')
                }}
              >
                <div className="flex flex-col gap-6 sm:gap-10">
                  <h3 className="font-semibold text-2xl sm:text-3xl">{fields.subtitle}</h3>
                  <div className="lg:hidden flex flex-col gap-3 sm:gap-5 xm:max-w-[300px] lg:max-w-[400px]">
                    <Content>{fields.description}</Content>
                  </div>
                  <div className="flex flex-col gap-4">
                    {fields?.form?.formId === 2 && (
                      <div className="grid md:grid-cols-2 md:gap-4">
                        <div className="sm:col-span-1">
                          <FormDuufField id={15} />
                        </div>
                        <div className="sm:col-span-1">
                          <FormDuufField id={16} />
                        </div>
                      </div>
                    )}
                    {fields?.form?.formId === 3 && (
                      <>
                        <div className="grid md:grid-cols-2 md:gap-4">
                          <div className="sm:col-span-1">
                            <FormDuufField id={15} />
                          </div>
                          <div className="sm:col-span-1" />
                        </div>
                        <FormDuufGroup showIds={[10, 16]} />
                        <FormDuufGroup hideIds={[10, 15, 16]} />
                      </>
                    )}
                    {fields?.form?.formId !== 3 && <FormDuufGroup hideIds={[15, 16]} />}

                    <FormDuufSubmit />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
