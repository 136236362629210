import clsx from 'clsx'
import type { theme } from '~/types/general'

export function Subtitle({ className, children, type = 'light' }: { className?: string; children: React.ReactNode; type?: theme }) {
  return (
    <div className={clsx(className && className, 'flex items-center gap-3')}>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.4806 4.25356C14.3122 4.25356 15.0998 4.57408 15.6983 5.1549C16.2968 5.73674 16.6255 6.50232 16.6255 7.31077V12.6892C16.6255 13.4977 16.2958 14.2633 15.6983 14.8451C15.0998 15.4269 14.3122 15.7464 13.4806 15.7464H7.52048C6.68883 15.7464 5.9013 15.4259 5.30277 14.8451C4.70424 14.2633 4.37557 13.4977 4.37557 12.6892V7.31077C4.37557 6.50232 4.70529 5.73674 5.30382 5.1549C5.90235 4.57306 6.68883 4.25356 7.52048 4.25356H13.4806ZM13.4806 0H7.52048C3.38432 0 0 3.28995 0 7.31077V12.6892C0 16.71 3.38432 20 7.52048 20H13.4795C17.6157 20 21 16.71 21 12.6892V7.31077C21.0011 3.28995 17.6167 0 13.4806 0Z"
          fill="#42BD3B"
        />
      </svg>
      <div className={clsx(type === 'light' && 'text-site-base', type === 'dark' && 'text-white', 'text-base xs:text-lg font-semibold ')}>
        {children}
      </div>
    </div>
  )
}
