import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentIconImage from './ContentIconImage'
import ContentTitle from './ContentTitle'
import ContentCustomerReview from './ContentCustomerReview'
import ContentAdvice from './ContentAdvice'
import ContentGoogleMaps from './ContentGoogleMaps'
import ContentImageSlider from './ContentImageSlider'
import ContentImage from './ContentImage'
import ContentUsps from './ContentUsps'
import ContentPersonCTA from './ContentPersonCTA'
import ContentFAQ from './ContentFAQ'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    content_default: ContentDefault,
    content_icon_image: ContentIconImage,
    content_title: ContentTitle,
    content_customer_review: ContentCustomerReview,
    content_advice: ContentAdvice,
    content_google_maps: ContentGoogleMaps,
    content_image_slider: ContentImageSlider,
    content_image: ContentImage,
    content_usps: ContentUsps,
    content_person_cta: ContentPersonCTA,
    content_faq: ContentFAQ
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
