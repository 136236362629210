import { LosseBlogBink, useLosseBlogBink, useSearchParams, type LosseBlogBinkReadableParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Accordion from '~/components/elements/Accordion'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'categorie'
}

export default function ContentFAQ({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <>
      {fields?.title && (
        <section data-component="ContentFAQ-Banner" className="section--pb pt-6 sm:pt-11 section--with-bg bg-site-alt">
          <div className="container">
            <div>
              <Breadcrumbs />
            </div>
            <div className="lg:pl-16 mt-11">
              <Title type="dark" className="font-semibold">
                {fields?.title}
              </Title>
            </div>
          </div>
        </section>
      )}
      <section data-component="ContentFAQ-Content" className="section--pb pt-10 md:pt-20 section--with-bg bg-site-grey">
        <div className="container">
          <LosseBlogBink readableParams={READABLE_PARAMS}>
            <Overview fields={fields} />
          </LosseBlogBink>
        </div>
      </section>
    </>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const { isFilterActive } = useLosseBlogBink()
  const [searchParams, setSearchParams] = useSearchParams()
  const [faqs, setFaqs] = useState(fields?.items)

  useEffect(() => {
    if (searchParams.get('categorie') === null) {
      setFaqs(fields?.items)
    }

    if (searchParams.get('categorie')) {
      const categorie = searchParams.get('categorie')!

      setFaqs(fields?.items?.filter((item: any) => item?.title === categorie))
    }
  }, [searchParams, fields?.items])

  return (
    <>
      {fields?.title && (
        <div className="flex flex-wrap items-center mb-4 lg:mb-6">
          <p className="lg:text-xl font-semibold mb-0">Filters</p>
          <div className="flex flex-wrap gap-2 ml-4 sm:ml-6">
            <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                className="absolute pointer-events-none z-20 top-0 bottom-0 right-4 my-auto"
              >
                <path
                  d="M6.99997 7.50003C6.73736 7.50049 6.47725 7.44904 6.23459 7.34865C5.99192 7.24825 5.7715 7.10089 5.58597 6.91503L0.292969 1.62103L1.70697 0.207031L6.99997 5.50003L12.293 0.207031L13.707 1.62103L8.41397 6.91403C8.22853 7.10007 8.00813 7.24762 7.76547 7.34819C7.5228 7.44875 7.26265 7.50036 6.99997 7.50003Z"
                  fill="#393C42"
                />
              </svg>

              <select
                className="bg-white relative focus-within:outline-site-base !appearance-none rounded w-[216px] h-[43px] pl-6 pr-4 placeholder:text-[#a4a4a4]"
                onChange={(e) => {
                  setSearchParams(
                    new URLSearchParams({
                      categorie: e.target.value
                    }),
                    {
                      state: {
                        scroll: false
                      }
                    }
                  )
                }}
                defaultValue={searchParams.get(READABLE_PARAMS.filters) || ''}
              >
                <option value="" className="text-[#a4a4a4]">
                  Alle vragen
                </option>
                {fields?.items?.map((item, index) => {
                  const isActive = isFilterActive(item?.title)
                  return (
                    <option className="bg-white text-[#a4a4a4]" key={index} value={item?.title} selected={isActive}>
                      {item?.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          {searchParams.size !== 0 && (
            <div
              onClick={() =>
                setSearchParams(new URLSearchParams(), {
                  state: {
                    scroll: false
                  }
                })
              }
              className="cursor-pointer max-sm:w-full max-sm:mt-2 sm:ml-6 underline"
            >
              Reset filter
            </div>
          )}
        </div>
      )}
      <div className={clsx(fields?.title ? 'lg:pl-14 xl:pl-28' : 'md:px-7 xl:px-14')}>
        {faqs?.map((item: any, index: number) => {
          return (
            <div key={index} className="py-8">
              {item?.title && (
                <Title size="small" className="text-center mb-6 lg:mb-12">
                  {item?.title}
                </Title>
              )}
              <Accordion defaultExpanded={index === 0 ? 0 : -1} fields={item?.items} withLinks />
            </div>
          )
        })}
      </div>
    </>
  )
}
