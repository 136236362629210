import { LosseBlogBink, type LosseBlogBinkReadableParams, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Posts, WpPageInfo, Maybe, ContentNode, RootQueryToPostConnection } from '~/graphql/types'
import Project from './post-types/Project'
import Pagination from '~/components/elements/product-overview/Pagination'

export const PROJECT_LIMIT = 6

export const PROJECT_READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'Categorie'
}

type ProductOverProps = Page_Flexcontent_Flex_Posts & {
  subdata: {
    Projects: RootQueryToPostConnection
  }
}

export default function ProjectOverview({ fields }: { fields: ProductOverProps }) {
  if (!fields?.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="ProjectOverview" className="section--pb pt-8 lg:pt-16 section--with-bg bg-site-grey">
      <div className="container">
        <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined} readableParams={PROJECT_READABLE_PARAMS}>
          <Overview fields={fields} />
        </LosseBlogBink>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: ProductOverProps) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Project: Project
}

function Overview({ fields }: { fields: ProductOverProps }) {
  const { isFilterActive } = useLosseBlogBink()
  const postTypeName = getPostTypeName(fields)
  const [_, setSearchParams] = useSearchParams()

  return (
    <>
      <div className="flex sm:flex-row flex-col sm:items-center mb-8 lg:mb-16">
        <p className="lg:text-xl flex gap-3 font-semibold mb-3 sm:mb-0">
          Filters
          {_.size !== 0 && (
            <div
              onClick={() =>
                setSearchParams(new URLSearchParams(), {
                  state: {
                    scroll: false
                  }
                })
              }
              className="cursor-pointer block font-light sm:hidden underline"
            >
              Reset filters
            </div>
          )}
        </p>
        <div className="flex max-xm:flex-wrap gap-2 sm:ml-6">
          {fields?.filters
            ?.filter((e) => e.parentDatabaseId === null)
            ?.map((filter, index) => (
              <div key={index} className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  className="absolute pointer-events-none z-20 top-0 bottom-0 right-4 my-auto"
                >
                  <path
                    d="M6.99997 7.50003C6.73736 7.50049 6.47725 7.44904 6.23459 7.34865C5.99192 7.24825 5.7715 7.10089 5.58597 6.91503L0.292969 1.62103L1.70697 0.207031L6.99997 5.50003L12.293 0.207031L13.707 1.62103L8.41397 6.91403C8.22853 7.10007 8.00813 7.24762 7.76547 7.34819C7.5228 7.44875 7.26265 7.50036 6.99997 7.50003Z"
                    fill="#393C42"
                  />
                </svg>
                <select
                  key={`project-filter-${index}`}
                  className="bg-white relative focus-within:outline-site-base !appearance-none rounded min-w-[180px] xm:min-w-[256px] h-[43px] pl-6 pr-4 placeholder:text-[#a4a4a4]"
                  onChange={(e) => {
                    _.set(PROJECT_READABLE_PARAMS.filters, e.target.value)
                    _.delete('Pagina')
                    setSearchParams(_, {
                      replace: true,
                      state: {
                        scroll: false
                      }
                    })
                  }}
                  defaultValue={_.get(PROJECT_READABLE_PARAMS.filters) || ''}
                >
                  <option value="" className="text-[#a4a4a4]">
                    {filter?.name}
                  </option>
                  {fields?.filters
                    ?.filter((e) => e.parentDatabaseId === filter?.databaseId)
                    ?.map((f, subIndex) => {
                      const isActive = isFilterActive(f?.name)
                      return (
                        <option className="bg-white text-[#a4a4a4]" key={subIndex} value={f?.name} selected={isActive}>
                          {f?.name}
                        </option>
                      )
                    })}
                </select>
              </div>
            ))}
        </div>
        {_.size !== 0 && (
          <div
            onClick={() =>
              setSearchParams(new URLSearchParams(), {
                state: {
                  scroll: false
                }
              })
            }
            className="cursor-pointer sm:block hidden sm:ml-6 mt-4 sm:mt-0 underline"
          >
            Reset filters
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 relative z-0">
        {fields?.subdata?.Projects?.edges?.map((edge) => {
          if (!edge || !edge.node) return null

          const Component = PostTypes[postTypeName] || PostTypes.Post

          return (
            <div key={edge.node.uri} className="col-span-1">
              <Component data={edge.node} />
            </div>
          )
        })}
      </div>
      <div className="mt-10 lg:mt-20">
        <Pagination total={fields?.subdata?.Projects.pageInfo?.offsetPagination?.total} limit={PROJECT_LIMIT} />
      </div>
    </>
  )
}
