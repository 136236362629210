import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef, useState, useEffect } from 'react'

export default function BannerBig({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const title = fields?.title?.split(' | ')
  const videoRef = useRef(null);
  const [sectionHeight, setSectionHeight] = useState(0);


  useEffect(() => {
    const calculateSectionHeight = () => {
      if (videoRef.current) {
        const videoWidth = videoRef.current.offsetWidth;
        // Assuming your video has a fixed aspect ratio, let's say 16:9
        const aspectRatio = 16 / 9;
        const sectionHeight = videoWidth / aspectRatio;
        setSectionHeight(sectionHeight);
      }
    };

    calculateSectionHeight();
    window.addEventListener('resize', calculateSectionHeight);

    return () => {
      window.removeEventListener('resize', calculateSectionHeight);
    };
  }, []);

  return (
    <section
      data-component="BannerBig"
      {...fields?.videoUrl && { style: { height: `${sectionHeight}px`, minHeight: '100px' } }}
      className={clsx(fields?.showFullHeight ? 'h-[calc(80vh_-_75px)] md:h-[calc(100vh_-_134px)]' : 'h-auto', 'relative section overflow-hidden section--with-bg')}
    >
      {fields?.videoUrl ? (
        <iframe ref={videoRef} className="absolute top-0 left-0 w-full h-full -z-10 object-cover" title="background-video" src={`${fields?.videoUrl}?autoplay=1&loop=1&title=0&byline=0&controls=0&muted=1`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
      ) : (
        fields?.image && (
          <div className="h-full w-full left-0 top-0 bg-site-base overflow-hidden absolute -z-10">
            <LossePlaatjie
              loading="eager"
              className={clsx(fields?.imagemobile && 'max-sm:hidden', ' w-full h-full object-cover')}
              src={fields.image}
              maxwidth={2000}
            />
            {fields?.imagemobile && (
              <LossePlaatjie loading="eager" className="sm:hidden w-full h-full object-cover" src={fields.imagemobile} maxwidth={2000} />
            )}
          </div>
        )
      )}
      <div className="container h-full relative">
        <div className="flex flex-col w-full h-full items-center justify-center">
          {fields?.title && (
            <h2 className="max-sm:flex max-sm:flex-col text-center py-2">
              {title.map((part, key) => (
                <span
                  key={key}
                  className={clsx(
                    'text-3xl xs:text-5xl uppercase text-center font-light lg:text-6xl xl:text-8xl mx-1 lg:mx-2 drop-shadow text-white',
                    key !== 0 ? 'font-semibold' : ''
                  )}
                >
                  {part}
                </span>
              ))}
            </h2>
          )}
          {fields?.description && (
            <div className="max-w-screen-md mx-auto lg:py-2">
              <Content className="content children-p:text-white children-p:font-normal children-p:text-lg md:children-p:text-2xl lg:children-p:text-3xl children-p:!leading-[1.5]">
                {fields?.description}
              </Content>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
