import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentDefault" className="section">
      <div className="container">
        <div className="max-w-[850px] mx-auto">
          <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold flex mb-5">{fields?.title}</h2>
          {fields?.description && <Content className="content">{fields?.description}</Content>}
          {fields?.links && (
            <div className="flex items-center gap-2 mt-10 ">
              {fields.links.map((link, index) => (
                <Button className={clsx(index >= 1 && 'btn--alt', 'btn')} key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
