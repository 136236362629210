import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Posts, Vacatures } from '~/graphql/types'
import Vacancy from './post-types/Vacancy'

export default function VacancyOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <>
      <section data-component="VacancyOverview" className="section section--with-bg bg-site-alt relative">
        <div className="container h-full w-full">
          <div className="absolute max-lg:top-4 lg:top-11">
            <Breadcrumbs />
          </div>
          <Title size="medium" type="dark" className="font-semibold capitalize md:pl-8 lg:pl-16">
            {fields?.title}
          </Title>
        </div>
      </section>
      <div className="section section--with-bg bg-site-grey">
        <div className="container h-full w-full grid max-lg:grid-rows-1 lg:grid-cols-2 gap-8 justify-center justify-items-start">
          {fields?.posts?.edges &&
            fields?.posts?.edges?.map((edge, key) => {
              const post = edge.node as Vacatures

              return <Vacancy key={key} data={post} />
            })}
        </div>
      </div>
    </>
  )
}
