export default function NoResults() {
  return (
    <div className="flex min-h-[300px] items-center justify-center">
      <div className="flex flex-col items-center gap-3 relative">
        <h1 className="animate-fade-up animate-delay-75 animate-ease-in-out text-site-base relative leading-[1] text-center font-bold">
          Sorry, we hebben niks kunnen vinden
        </h1>
        <h2 className="text-2xl lg:text-3xl relative max-w-screen-sm text-center animate-fade-up animate-ease-in-out animate-delay-150 font-bold text-site-base">
          Probeer het met andere filters.
        </h2>
      </div>
    </div>
  )
}
