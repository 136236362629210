import { useLoaderData } from '@ubo/losse-sjedel'
import type { LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'

export interface FooterLoaderData extends Omit<LoaderData, 'footer' | 'rest'> {
  footer: Component_Footer
  rest: {
    googlePlaces:  {
      totalReviews: number
      totalRating: number
    }
  }
}

export default function useReviews() {
  const { rest } = useLoaderData<FooterLoaderData>()

  return rest?.googlePlaces
}
