import clsx from 'clsx'
import type { theme, size } from '~/types/general'

export function Title({
  className,
  children,
  size = 'medium',
  type = 'light'
}: {
  className?: string
  children: React.ReactNode
  size?: size
  type?: theme
}) {
  return (
    <h2
      className={clsx(
        type === 'light' && 'text-site-base',
        type === 'dark' && 'text-white',
        size === 'medium' && 'text-3xl xs:text-4xl sm:text-5xl xl:text-6xl sm:mt-3',
        size === 'small' && 'text-2xl xs:text-3xl sm:text-4xl lg:text-5xl',
        'font-light uppercase',
        className && className
      )}
    >
      {children}
    </h2>
  )
}
