import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerHome from './BannerHome'
import BannerProject from './BannerProject'
import BannerVacancy from './BannerVacancy'
import BannerImage from './BannerImage'
import BannerProduct from './BannerProduct'
import BannerSearch from './BannerSearch'
import BannerBig from './BannerBig'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    banner_default: BannerDefault,
    banner_home: BannerHome,
    banner_big: BannerBig,
    banner_project: BannerProject,
    banner_vacancy: BannerVacancy,
    banner_image: BannerImage,
    banner_product: BannerProduct,
    banner_search: BannerSearch
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
