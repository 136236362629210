import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Content from './Content'
import clsx from 'clsx'
import Button from './Button'

export default function Accordion({
  defaultExpanded = 0,
  className,
  fields,
  withLinks
}: {
  defaultExpanded?: number
  className?: string
  fields: any
  withLinks?: any
}) {
  const [expanded, setExpanded] = useState<false | number>(defaultExpanded)

  return (
    <div className={className}>
      {fields?.map((item: any, index: number) => {
        const isOpen = index === expanded

        return (
          <div
            className="bg-white overflow-hidden last:mb-0 mb-4 group sm:mb-7 py-7 md:py-10 px-7 md:px-14 shadow-site-shadow rounded-[7px]"
            key={index}
          >
            <motion.header
              initial={false}
              onClick={() => setExpanded(isOpen ? false : index)}
              className="text-xl lg:text-3xl text-site-base font-semibold cursor-pointer flex items-center justify-between"
            >
              <span>{item?.title}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                className={clsx(!isOpen && 'rotate-180', 'min-w-[24px] min-h-[14px] max-w-[24px] max-h-[14px]')}
              >
                <path
                  d="M22.5859 13.071L12.7069 3.19204C12.5167 3.00955 12.2634 2.90765 11.9999 2.90765C11.7363 2.90765 11.483 3.00955 11.2929 3.19204L1.41986 13.066L0.00585938 11.652L9.87886 1.77804C10.4507 1.23302 11.2104 0.928994 12.0004 0.928994C12.7903 0.928994 13.55 1.23302 14.1219 1.77804L23.9999 11.657L22.5859 13.071Z"
                  fill="#42BD3B"
                />
              </svg>
            </motion.header>
            <AnimatePresence initial={false}>
              {isOpen && (
                <motion.div
                  key="content"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.4, bounce: 0 }}
                >
                  {withLinks ? (
                    <div className="grid lg:grid-cols-3 gap-4 xl:gap-20 items-end">
                      <div className="xs:col-span-2">
                        <Content className="pt-6">{item?.description}</Content>
                      </div>
                      <div className="col-span-1">
                        {item?.microcopy && <p className="font-bold group-last:mb-0 mb-4">{item?.microcopy}</p>}
                        {item?.link && <Button to={item?.link?.url}>{item?.link?.title}</Button>}
                      </div>
                    </div>
                  ) : (
                    <Content className="pt-6">{item?.description}</Content>
                  )}
                  {item?.videoUrl && (
                    <div className="w-full aspect-video mt-6 md:mt-10 rounded-md overflow-hidden">
                      <iframe src={item?.videoUrl} width="100%" height="100%" title={item?.title} />
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )
      })}
    </div>
  )
}
