import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Arrow } from '~/components/elements/Arrow'
import Links from '~/components/elements/Links'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function ServicesOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (fields?.items?.length === 0) return null

  return (
    <section data-component="ServicesOverview" className="section section--with-bg bg-site-base">
      <div className="container">
        <Subtitle type="dark">{fields?.subtitle}</Subtitle>
        <Title type="dark">{fields?.title}</Title>
        <div className="mt-6 sm:mt-14">
          <div className="grid grid-cols-1 xm:grid-cols-2 sm:grid-cols-9 md:grid-cols-12 lg:[grid-template-columns:repeat(16,minmax(0,1fr))] gap-4 md:gap-6">
            {fields?.items?.map((item, i) => {
              const title = item.normallink ? item?.link?.title : item?.replacementtitle || item?.relationitem?.title

              return (
                <div
                  className={clsx(
                    i === 0 && 'sm:col-span-5 md:col-span-7 lg:col-span-6 h-[170px] xm:h-[280px] sm:h-[340px]',
                    i === 1 && 'sm:col-span-4 md:col-span-5 lg:col-span-6 h-[170px] xm:h-[280px] sm:h-[340px]',
                    i === 2 && 'sm:col-span-4 md:col-span-5 lg:col-span-4 h-[170px] sm:h-[210px] lg:h-[340px]',
                    i === 3 && 'sm:col-span-5 md:col-span-7 lg:col-span-5 h-[170px] sm:h-[210px]',
                    i === 4 && 'sm:col-span-5 md:col-span-7 lg:col-span-6 h-[170px] sm:h-[210px]',
                    i === 5 && 'sm:col-span-4 md:col-span-5 lg:col-span-5 h-[170px] sm:h-[210px]',
                    'bg-site-alt relative overflow-hidden group hover:bg-site-base !transition-all !duration-200 rounded-[7px] shadow-site-shadow'
                  )}
                  key={i}
                >
                  <LosseLink to={item.normallink ? item?.link?.url : item?.relationitem?.uri} className="flex flex-col items-end h-full">
                    {item.normallink ? (
                      item?.image && (
                        <LossePlaatjie
                          maxwidth={1000}
                          className="absolute group-hover:opacity-50 transition-all z-10 duration-200 object-cover h-full w-full top-0 left-0"
                          src={item.image}
                        />
                      )
                    ) : (
                      <LossePlaatjie
                        maxwidth={1000}
                        className="absolute group-hover:opacity-50 transition-all z-10 duration-200 object-cover h-full w-full top-0 left-0"
                        src={item.relationitem.featuredImage.node}
                      />
                    )}
                    <div className="flex gap-3 w-full h-full relative z-20 px-4 xl:px-6 pb-3 sm:pb-5 items-end justify-between">
                      <span className="text-lg sm:text-2xl xl:text-3xl uppercase drop-shadow !leading-[1] text-white font-light">
                        {title.split('|').map((t, i) => (
                          <div className={clsx(i === 0 && 'font-semibold')} key={i}>
                            {t}
                            <br />
                          </div>
                        ))}
                      </span>
                      <Arrow size="medium" />
                    </div>
                  </LosseLink>
                </div>
              )
            })}
          </div>
          {fields?.links && (
            <div className="flex mt-6 sm:mt-16 justify-center ">
              <Links items={fields.links} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
