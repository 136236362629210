import type { Page_Flexcontent_Flex_Posts, Project as ProjectProps } from '~/graphql/types'
import Project from './post-types/Project'
import Links from '~/components/elements/Links'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'

export default function ProjectHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (fields?.projects?.length === 0) return null

  return (
    <section data-component="ProjectHighlighted" className="section overflow-hidden relative">
      <div className="container">
        <Subtitle>{fields?.subtitle}</Subtitle>
        <Title>{fields?.title}</Title>
        <div className="grid md:grid-cols-2 gap-8 my-8 lg:my-16 relative">
          {fields?.projects?.map((project, index) => (
            <div className="col-span-1" key={index}>
              <Project data={project as ProjectProps} />
            </div>
          ))}
        </div>
        {fields?.links && (
          <div className="mt-4 flex justify-center">
            <Links items={fields.links} icons="arrow" />
          </div>
        )}
      </div>
    </section>
  )
}
