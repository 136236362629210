import clsx from 'clsx'

type color = 'base' | 'accent' | 'white'

// swiper-button-disabled
export function Arrow({
  orientation = 'right',
  color = 'accent',
  hover = 'white',
  size = 'medium'
}: {
  orientation?: 'left' | 'right'
  color?: color
  hover?: color
  size?: 'small' | 'medium' | 'large'
}) {
  return (
    <svg
      className={clsx(
        size === 'small' && 'w-[38px] h-[22px] min-w-[38px] min-h-[22px]',
        size === 'medium' && 'w-[38px] h-[22px] md:w-[48px] md:h-[26px] md:min-w-[48px] md:min-h-[26px]',
        size === 'large' && 'w-[58px] h-[30px] min-w-[58px] min-h-[30px]',
        orientation === 'left' && 'rotate-180',
        'group'
      )}
      viewBox="0 0 38 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_709_2068)">
        <path
          d="M37.4628 9.00339L0.536536 8.99674C0.240494 8.99674 0 8.74337 0 8.4308C0 8.11823 0.240494 7.86552 0.536536 7.86552L28.0444 7.87018L24.6642 0.969115C24.5544 0.744998 24.5992 0.471004 24.7734 0.298095C24.9477 0.125186 25.2102 0.0945947 25.416 0.224276L37.7374 7.95197C37.9451 8.08232 38.0448 8.34235 37.9804 8.58775C37.916 8.83315 37.7046 9.00339 37.4628 9.00339ZM29.2526 7.87084L35.5111 7.87217L26.479 2.20674L29.2532 7.87084H29.2526Z"
          className={clsx(
            color === 'base' && 'fill-site-base',
            color === 'accent' && 'fill-site-accent',
            color === 'white' && 'fill-white',
            hover === 'base' && 'group-hover:fill-site-base',
            hover === 'accent' && 'group-hover:fill-site-accent',
            hover === 'white' && 'group-hover:fill-white',
            'transition-all duration-200'
          )}
        />
        <path
          d="M25.1421 21.855C25.0083 21.855 24.8763 21.8025 24.7741 21.7014C24.5999 21.5285 24.555 21.2545 24.6655 21.0304L28.0457 14.1346L0.536536 14.1346C0.240494 14.134 0 13.8806 0 13.5687C0 13.2568 0.240494 13.0034 0.536536 13.0034L37.4628 13.0034C37.7046 13.0034 37.916 13.1737 37.9804 13.4191C38.0442 13.6645 37.9451 13.9252 37.7374 14.0548L25.416 21.7759C25.3308 21.8291 25.2361 21.8557 25.1421 21.8557V21.855ZM29.2538 14.134L26.4796 19.7934L35.5111 14.134H29.2545H29.2538Z"
          className={clsx(
            color === 'base' && 'fill-site-base',
            color === 'accent' && 'fill-site-accent',
            color === 'white' && 'fill-white',
            hover === 'base' && 'group-hover:fill-site-base',
            hover === 'accent' && 'group-hover:fill-site-accent',
            hover === 'white' && 'group-hover:fill-white',
            'transition-all duration-200'
          )}
        />
      </g>
      <defs>
        <clipPath id="clip0_709_2068">
          <rect width="37.9998" height="21.7113" fill="white" transform="translate(0 0.144348)" />
        </clipPath>
      </defs>
    </svg>
  )
}
