import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Project as Props } from '~/graphql/types'

export default function Project({ data, customHeight }: { data: Props; customHeight?: string }) {
  if (!data.title) return null

  return (
    <LosseLink to={data.uri} className="relative group">
      <div className={clsx(customHeight ? customHeight : 'h-[350px] lg:h-[403px]', 'bg-site-base rounded-[7px]')}>
        <div className="aspect-video w-full h-full rounded-[7px] shadow-site-shadow overflow-hidden absolute top-0 left-0">
          <LossePlaatjie
            src={data.featuredImage?.node}
            className="w-full h-full group-hover:opacity-50 transition-all duration-200 object-cover"
            maxwidth={2000}
            title={data.title}
          />
        </div>
        <div className="w-full h-full relative z-10 flex flex-col justify-between p-4 md:p-5 lg:p-10">
          <div className="flex flex-wrap gap-y-2 gap-x-3">
            {/* {data?.categories?.edges?.map((category, index) => (
              <p key={index} className="bg-white text-site-base px-5 py-2 rounded-full text-xs">
                {category?.node?.name}
              </p>
            ))} */}
          </div>
          <div>
            <h2 className="text-white text-2xl md:text-3xl line-clamp-1 drop-shadow mb-3">
              {data?.title.split('–').map((t, i) => (
                <span className={clsx(i === 0 && 'font-semibold', i === 1 && 'font-light')} key={i}>
                  {t}
                  {i === 0 && ` - `}
                </span>
              ))}
            </h2>
            <div className="text-white drop-shadow font-semibold flex items-center">
              <span className="mr-3">Bekijk dit project</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="38" height="22" viewBox="0 0 38 22" fill="none">
                <g clipPath="url(#clip0_817_823)">
                  <path
                    d="M25.1421 22C25.0083 22 24.8763 21.9475 24.7741 21.8464C24.5999 21.6735 24.555 21.3995 24.6655 21.1754L28.0457 14.2797L0.536536 14.2797C0.240494 14.279 0 14.0256 0 13.7137C0 13.4018 0.240494 13.1484 0.536536 13.1484L37.4628 13.1484C37.7046 13.1484 37.916 13.3187 37.9804 13.5641C38.0442 13.8095 37.9451 14.0702 37.7374 14.1999L25.416 21.9209C25.3308 21.9741 25.2361 22.0007 25.1421 22.0007V22ZM29.2538 14.279L26.4796 19.9384L35.5111 14.279H29.2545H29.2538Z"
                    fill="#42BD3B"
                  />
                  <path
                    d="M37.4628 8.8588L0.536536 8.85215C0.240494 8.85215 0 8.59877 0 8.28621C0 7.97364 0.240494 7.72093 0.536536 7.72093L28.0444 7.72558L24.6642 0.824522C24.5544 0.600406 24.5992 0.326412 24.7734 0.153503C24.9477 -0.019406 25.2102 -0.0499976 25.416 0.0796841L37.7374 7.80738C37.9451 7.93773 38.0448 8.19776 37.9804 8.44316C37.916 8.68855 37.7046 8.8588 37.4628 8.8588ZM29.2526 7.72625L35.5111 7.72758L26.479 2.06215L29.2532 7.72625H29.2526Z"
                    fill="#42BD3B"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </LosseLink>
  )
}
