import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import PageDownArrow from '~/components/elements/PageDownArrow'
import useReviews from '~/hooks/useReviews'
import { Fragment, useEffect, useRef, useState } from 'react'
import useIsMobile from '~/hooks/useIsMobile'

export default function BannerHome({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const videoRef = useRef(null)
  const isMobile = useIsMobile()
  const [sectionHeight, setSectionHeight] = useState(0)
  const reviews = useReviews()
  const stars = []

  for (let i = 0; i < reviews?.totalRating; i++) {
    stars.push(i)
  }

  useEffect(() => {
    const calculateSectionHeight = () => {
      if (videoRef.current) {
        const videoWidth = videoRef.current.offsetWidth
        // Assuming your video has a fixed aspect ratio, let's say 16:9
        const aspectRatio = 16 / 9
        const sectionHeight = videoWidth / aspectRatio
        setSectionHeight(sectionHeight)
      }
    }

    calculateSectionHeight()
    window.addEventListener('resize', calculateSectionHeight)

    return () => {
      window.removeEventListener('resize', calculateSectionHeight)
    }
  }, [])

  return (
    <Fragment>
      <section data-component="BannerHome-ReviewStrip" className="bg-white py-[6px]">
        <div className="container">
          <div className="flex justify-center items-center">
            <a
              href="https://www.google.com/search?q=toc+eemland+google+reviews&oq=toc+eemland+google+reviews&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQLhhA0gEIMzgxNmowajGoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x47c64752c62f224d:0xec57c4f42c43236b,1,,,,"
              className="text-site-base font-light text-sm mr-3 max-md:underline"
            >
              <span>Klantbeoordeling: </span> {reviews?.totalRating * 2}
            </a>
            <div className="flex items-center gap-[2px]">
              {stars?.map((star, index) => (
                <svg
                  className="xs:w-[21px] xs:h-[20px] w-[15px] h-[14px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  key={index}
                >
                  <g clipPath="url(#clip0_1157_21)">
                    <path
                      d="M9.88209 1.60996C10.0529 1.19932 10.6346 1.19932 10.8054 1.60996L12.8715 6.57744C12.9435 6.75056 13.1063 6.86885 13.2932 6.88383L18.656 7.31376C19.0993 7.3493 19.2791 7.90256 18.9413 8.19189L14.8554 11.6919C14.713 11.8139 14.6509 12.0053 14.6944 12.1876L15.9427 17.4208C16.0459 17.8534 15.5752 18.1954 15.1957 17.9635L10.6044 15.1592C10.4444 15.0615 10.2431 15.0615 10.0831 15.1592L5.49181 17.9635C5.11226 18.1954 4.64163 17.8534 4.74483 17.4208L5.99314 12.1876C6.03664 12.0053 5.97445 11.8139 5.83206 11.6919L1.74616 8.19189C1.40839 7.90256 1.58816 7.3493 2.03148 7.31376L7.39429 6.88383C7.58119 6.86885 7.74399 6.75056 7.816 6.57744L9.88209 1.60996Z"
                      fill="#42BD3B"
                    />
                  </g>
                </svg>
              ))}
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              className="text-sm ml-3 underline md:block hidden"
              href="https://www.google.com/search?q=toc+eemland+google+reviews&oq=toc+eemland+google+reviews&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQLhhA0gEIMzgxNmowajGoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x47c64752c62f224d:0xec57c4f42c43236b,1,,,,"
            >
              Bekijk hier alle reviews
            </a>
          </div>
        </div>
      </section>
      <section
        data-component="BannerHome"
        {...(fields?.videoUrl && { style: { height: `${sectionHeight}px`, minHeight: '100px' } })}
        className="relative section section--with-bg h-[calc(80vh_-_75px)] md:h-[calc(100vh_-_134px)] min-h-[500px]"
      >
        {fields?.videoUrl ? (
          <iframe
            ref={videoRef}
            className="absolute top-0 left-0 w-full h-full -z-10 object-cover"
            title="background-video"
            src={`${fields?.videoUrl}?autoplay=1&loop=1&title=0&byline=0&controls=0&muted=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        ) : (
          fields?.image && (
            <div className="h-full w-full left-0 top-0 bg-site-base overflow-hidden absolute -z-10">
              <LossePlaatjie
                loading="eager"
                className={clsx(fields?.imagemobile && 'max-sm:hidden', ' w-full h-full object-cover')}
                src={fields.image}
                maxwidth={2000}
              />
              {fields?.imagemobile && (
                <LossePlaatjie loading="eager" className="sm:hidden w-full h-full object-cover" src={fields.imagemobile} maxwidth={640} />
              )}
            </div>
          )
        )}
        <div className="container h-full relative">
          {!fields.videoUrl && !isMobile ? (
            <PageDownArrow className="absolute max-lg:right-0 max-lg:mx-auto left-5 -bottom-5 sm:bottom-0" />
          ) : (
            fields?.videoUrl &&
            !isMobile && <PageDownArrow className="absolute max-lg:right-0 max-lg:mx-auto left-5 -bottom-5 sm:bottom-0" />
          )}

          <div className="flex flex-col w-full h-full items-center justify-center">
            {fields?.title && (
              <h1 className="max-sm:flex max-sm:flex-col text-center py-2">
                {fields?.title?.split(' | ').map((part, key) => (
                  <span
                    key={key}
                    className={clsx(
                      'text-5xl uppercase text-center font-light lg:text-6xl xl:text-8xl mx-1 lg:mx-2 drop-shadow text-white',
                      key !== 0 ? 'font-semibold' : ''
                    )}
                  >
                    {part}
                  </span>
                ))}
              </h1>
            )}
            {fields?.description && (
              <div className="max-w-screen-md mx-auto lg:py-2">
                <Content className="content children-p:text-white children-p:font-normal children-p:text-lg md:children-p:text-2xl lg:children-p:text-3xl children-p:!leading-[1.5]">
                  {fields?.description}
                </Content>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  )
}
