import { LossePlaatjie } from '@ubo/losse-sjedel'
import Links from '~/components/elements/Links'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function ProductSingleHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="ProductSingleHighlighted" className="section section--with-bg bg-site-alt">
      <div className="container">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-10 xl:gap-20">
          <div className="col-span-2 flex items-center">
            <div>
              <Subtitle type="dark">{fields?.subtitle}</Subtitle>
              <Title type="dark">{fields?.title}</Title>
              {fields.links && <Links className="mt-4 sm:mt-8 xl:mt-12 max-md:hidden" items={fields.links} />}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-3 flex justify-center items-center">
            <LossePlaatjie
              maxwidth={1000}
              className="rounded-[7px] max-h-[480px] object-cover shadow-site-shadow"
              src={fields?.customFeaturedImage ? fields?.customFeaturedImage : fields?.product?.featuredImage?.node}
            />
          </div>
          {fields.links && <Links className="col-span-2 md:hidden" items={fields.links} />}
        </div>
      </div>
    </section>
  )
}
