import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormContact from './FormContact'
import FormPersonWithBanner from './FormPersonWIthBanner'
import FormPerson from './FormPerson'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    form_contact: FormContact,
    form_person: FormPerson,
    form_person_with_banner: FormPersonWithBanner
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
