/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from '@ubo/losse-sjedel'
import Slider from 'rc-slider'
import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { READABLE_PARAMS } from '~/graphql/Queries'

export default function RangeFilter({ min, max, slug, label }: { min: number; max: number; slug: string; label: string }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultValues = {
    min: parseInt(searchParams.get(slug)?.split('-')[0] || min.toString()),
    max: parseInt(searchParams.get(slug)?.split('-')[1] || max.toString())
  }

  const [currentRange, setCurrentRange] = useState(defaultValues)
  const debouncedValue = useDebounce<{
    min: number
    max: number
  }>(currentRange, 500)
  const initialised = useRef(false)

  useEffect(() => {
    if (searchParams.toString() !== '') return

    if (JSON.stringify(defaultValues) === JSON.stringify(currentRange)) return

    setCurrentRange(defaultValues)
  }, [searchParams.toString()])

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true
      return
    }

    if (!debouncedValue) {
      if (searchParams.get(READABLE_PARAMS.price)) {
        searchParams.delete(READABLE_PARAMS.price)
        setSearchParams(searchParams, {
          state: {
            scroll: false
          }
        })
      }
      return
    }

    if (JSON.stringify(debouncedValue) === JSON.stringify(defaultValues)) return

    searchParams.set(READABLE_PARAMS.price, `${debouncedValue.min}-${debouncedValue.max}`)
    setSearchParams(searchParams, {
      state: {
        scroll: false
      }
    })
    // eslint-disable-next-line
  }, [debouncedValue])

  return (
    <div className="py-2">
      <div className="text-lg font-semibold mb-2 block">{label}</div>
      <div>
        <div className="flex flex-col">
          <div className="relative mt-1">
            <div className="bg-transparent flex gap-3 items-center">
              <input
                max={currentRange.max - 1}
                min={min}
                defaultValue={currentRange.min || debouncedValue.min}
                value={currentRange.min || defaultValues.min}
                onChange={(e) => setCurrentRange({ ...currentRange, min: parseInt(e.target.value) })}
                className="bg-site-grey text-center rounded-[7px] h-9 w-[75px] border border-site-alt px-2 py-1"
              />
              <div>-</div>
              <input
                max={max}
                min={currentRange.min + 1}
                defaultValue={currentRange.max || defaultValues.max}
                value={currentRange.max || defaultValues.max}
                onChange={(e) => setCurrentRange({ ...currentRange, max: parseInt(e.target.value) })}
                className="bg-site-grey text-center rounded-[7px] h-9 w-[75px] border border-site-alt px-2 py-1"
              />
            </div>
            <div className="px-3 mt-6">
              <Slider
                range
                allowCross={false}
                step={1}
                min={min}
                max={max}
                defaultValue={[
                  parseInt(searchParams.get(slug)?.split('-')[0] || min.toString()),
                  parseInt(searchParams.get(slug)?.split('-')[1] || max.toString())
                ]}
                value={[currentRange.min, currentRange.max]}
                onChange={(e: any) => setCurrentRange({ min: e[0], max: e[1] })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
